import React from 'react';
import { Box, Typography, Container, Link, List, ListItem, ListItemText } from '@mui/material';
import TopNavBar from '../../components/TopNavBar';
import UserController from '../../controllers/UserController';

const Privacy: React.FC = () => {

    const { user, signOut, route, authStatus } = UserController()
    return (
        <TopNavBar user={user} signOut={signOut} route={route} authStatus={authStatus}>
            <Box sx={{ backgroundColor: '#f4f4f4', minHeight: '100vh', padding: '2rem 0' }}>
                <Container maxWidth="md">
                    <Typography variant="h4" component="h1" gutterBottom>
                        Privacy Policy
                    </Typography>
                    <Typography variant="body1" paragraph>
                        To ensure that the website delivers a secure and effective service, peep.lk needs to collect, utilize, and share personal information.
                    </Typography>

                    <Typography variant="h6" component="h2" gutterBottom>
                        Collection
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Information shared on peep.lk is accessible to the public. By providing us with personal details, you consent to the transfer and storage of that information on our servers. We collect and retain the following types of personal information:
                    </Typography>
                    <ul>
                        <li>Email address, contact information.</li>
                        <li>Computer sign-on data, statistics on page views, traffic to and from peep.lk, and response to advertisements.</li>
                        <li>Other information, including users' IP address and standard web log information.</li>
                    </ul>

                    <Typography variant="h6" component="h2" gutterBottom>
                        Use
                    </Typography>
                    <Typography variant="body1" paragraph>
                        We use users' personal information to:
                    </Typography>
                    <ul>
                        <li>Provide our services</li>
                        <li>Resolve disputes, and troubleshoot problems</li>
                        <li>Encourage safe trading and enforce our policies</li>
                        <li>Customize users' experience, measure interest in our services</li>
                        <li>Improve our services and inform users about services and updates</li>
                        <li>Communicate marketing and promotional offers to you</li>
                        <li>Do other things for users as described when we collect the information</li>
                    </ul>
                    <Typography variant="h6" component="h2" gutterBottom>
                        Disclosure
                    </Typography>
                    <Typography variant="body1" paragraph>
                        We don't sell or rent users' personal information to third parties for their marketing purposes without users' explicit consent. We may disclose personal information to respond to legal requirements, enforce our policies, respond to claims that a posting or other content violates others' rights, or protect anyone's rights, property, or safety.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        peep.lk will share your phone number and/or email address with the buyers/sellers to whom you have contacted or shown interest through our platform, to make sure you get the best possible offers on products that you have shown an active interest in. You hereby give consent to such sharing of phone number and/or email ID.
                    </Typography>

                    <Typography variant="h6" component="h2" gutterBottom>
                        Communication and Email Tools
                    </Typography>
                    <Typography variant="body1" paragraph>
                        You agree to receive marketing communications about consumer goods and services on behalf of our third-party advertising partners unless you will be prompt by that service for set ot unset it.
                    </Typography>

                    <Typography variant="h6" component="h2" gutterBottom>
                        Security
                    </Typography>
                    <Typography variant="body1" paragraph>
                        We use various tools (encryption, passwords, physical security) to protect your personal information against unauthorized access and disclosure.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        All personal electronic details will be kept private by the Service except for those that you wish to disclose.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Disclosing others' contact information through the Service is prohibited.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        By violating the laws of your country of residence or the terms of use of our Service, you forfeit your privacy rights regarding your personal information.
                    </Typography>

                    <Typography variant="h6" component="h2" gutterBottom>
                        Remove Information
                    </Typography>
                    <Typography variant="body1" paragraph>
                        If you would like to review or remove your information from our active databases at any time, please reach out to us at <Link href="mailto:send.peep@gmail.com">send.peep@gmail.com</Link>.
                    </Typography>

                    <Typography variant="body1" paragraph>
                        This website utilizes Display Advertising and Remarketing technology through Google Analytics for online advertising. Third-party vendors, such as Google, may display our ads on different websites across the Internet. These ads are served using a combination of first-party and third-party cookies to enhance, optimize, and target ads based on your previous visits to our site.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Visitors have the option to opt out of Google Analytics for Display Advertising and adjust their Google Display Network ad preferences through the Ads Preferences Manager.
                    </Typography>
                </Container>
            </Box>
        </TopNavBar>
    );
};

export default Privacy;
