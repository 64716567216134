import React from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";

const UserController = () => {
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const { route } = useAuthenticator((context) => [context.route]);
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);

  React.useEffect(() => {}, []);

  return {
    user,
    signOut,
    route,
    authStatus,
  };
};
export default UserController;
