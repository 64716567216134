import React from 'react';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, FacebookIcon, TwitterIcon, WhatsappIcon, XIcon } from 'react-share';

const ShareComponent: React.FC<{ id: string }> = ({ id }) => {
    const shareUrl = `https://www.peep.lk/details/${id}`;
    const title = 'Peep.lk - Free ads, Buy,Sell,Vehicles in Sri Lanka ';

    const handleNativeShare = async () => {
        if (navigator.share) {
            try {
                await navigator.share({
                    title: shareUrl,
                    text: `Check out this ad: ${title}`,
                    url: shareUrl,
                });
                // console.log('Shared successfully');
            } catch (error) {
                console.error('Error sharing', error);
            }
        } else {
            // console.log('Web Share API not supported.');
        }
    };

    // const shareOnFacebook = (id) => {
    //     const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=https://www.peep.lk/details/${id}`;
    //     window.open(shareUrl, '_blank');
    // };

    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', maxWidth: '200px', margin: 'auto' }}>
                <FacebookShareButton style={{ paddingRight: '10px' }} url={shareUrl} title={title}>
                    <FacebookIcon size={32} round />
                </FacebookShareButton>
                <TwitterShareButton style={{ paddingRight: '10px' }} url={shareUrl} title={title}>
                    <XIcon size={32} round />
                </TwitterShareButton>
                <WhatsappShareButton style={{ paddingRight: '10px' }} url={shareUrl} title={title}>
                    <WhatsappIcon size={32} round />
                </WhatsappShareButton>
                <button onClick={handleNativeShare} style={{ padding: '5px 10px', cursor: 'pointer' }}>
                    Share
                </button>
            </div>

        </div>
    );
};

export default ShareComponent;
