import React from 'react';
import { Box, Typography, Grid, Link, IconButton, Button } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTube from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { useNavigate } from 'react-router-dom';
import { TikTokIcon } from './TiktokIcon';
import XIcon from '@mui/icons-material/X';
import TikTokEmbed from './TikTokEmbed';

const Footer: React.FC = () => {
    const navigate = useNavigate();
    const baseUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://peep.lk';

    const links = [
        { label: 'Terms & Conditions', url: `/terms` },
        { label: 'Help', url: `/help` },
        { label: 'Privacy Policy', url: `/privacy` },
        { label: 'About Us', url: `/about` },
    ];

    const socialLinks = [
        { icon: <FacebookIcon />, url: 'https://www.facebook.com/peepdotlk' },
        { icon: <YouTube />, url: 'https://www.youtube.com/@peeplk' },
        { icon: < TikTokIcon />, url: 'https://www.tiktok.com/@peep.lk' },
        { icon: <LinkedInIcon />, url: 'https://www.linkedin.com/company/peep-lk' },
        { icon: <XIcon />, url: 'https://x.com/peep_lk' },

    ];

    return (
        <Box sx={{ backgroundColor: '#f6f4f3', textAlign: 'center', paddingBottom: 8 }}>
            <Box
                sx={{
                    width: '100%',
                    height: '15px', // Full row height
                    display: 'flex',
                    margin: 0,
                    padding: 0,
                }}
            >
                <Box sx={{ flex: '1 1 20%', backgroundColor: '#c02219' }} />
                <Box sx={{ flex: '1 1 30%', backgroundColor: '#fcadaf' }} />
                <Box sx={{ flex: '1 1 10%', backgroundColor: '#f52f26' }} />
                <Box sx={{ flex: '1 1 15%', backgroundColor: '#bf221e' }} />
                <Box sx={{ flex: '1 1 5%', backgroundColor: '#bf221e' }} />
                <Box sx={{ flex: '1 1 10%', backgroundColor: '#FFC300' }} />
                <Box sx={{ flex: '1 1 10%', backgroundColor: '#581845' }} />
            </Box>
            <Grid container justifyContent="center" spacing={2} sx={{ marginTop: 2 }}>
                {links.map((link, index) => (
                    <Grid item key={index}>
                        <Button

                            onClick={() => navigate(link.url)}
                            rel="noopener noreferrer"
                            color="inherit"
                            // underline="none"
                            sx={{
                                position: 'relative',
                                '&:hover::after': {
                                    content: '""',
                                    position: 'absolute',
                                    width: '100%',
                                    height: '2px',
                                    bottom: '-2px',
                                    left: '0',
                                    backgroundColor: 'currentColor',
                                },
                            }}
                        >
                            {link.label}
                        </Button>
                    </Grid>
                ))}
            </Grid>
            <Grid container justifyContent="center" spacing={2} sx={{ marginTop: 2 }}>
                {socialLinks.map((social, index) => (
                    <Grid item key={index}>
                        <Link href={social.url} target="_blank" rel="noopener noreferrer">
                            <IconButton
                                sx={{
                                    color: 'black',
                                    '&:hover': {
                                        backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                        borderRadius: '50%',
                                    },
                                }}
                            >
                                {social.icon}
                            </IconButton>
                        </Link>
                    </Grid>
                ))

                }

            </Grid>

            <Typography variant="body1" color="textSecondary" sx={{ marginTop: 2 }}>
                &copy; 2024 peep.lk. All rights reserved.
            </Typography>


        </Box>
    );
};

export default Footer;
