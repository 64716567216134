import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { addPost, updateUserPost, deleteUserPost } from "../actions/PostAction";
import { ApiResponse } from "../../constants/constants";

interface AddPostState {
  status: boolean;
  addPostloader: boolean;
  deletePostloader: boolean;
  deleteStatus: boolean;
  addPostError: string | null;
}

// Initial state
const initialState: AddPostState = {
  status: false,
  addPostloader: false,
  deletePostloader: false,
  deleteStatus: false,
  addPostError: null,
};

export const addPostsSlice = createSlice({
  name: "ADD_POST",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addPost.fulfilled, (state, action: PayloadAction<any>) => {
      state.status = true;
      state.addPostloader = false;
      state.addPostError = null;
    });
    builder.addCase(addPost.pending, (state, action) => {
      state.status = false;
      state.addPostloader = true;
      state.addPostError = null;
    });
    builder.addCase(addPost.rejected, (state, action) => {
      state.addPostError = (action.payload as string) || "Failed to addPost";
      state.status = false;
      state.addPostloader = false;
    });

    //Update post------------------------------------------------------

    builder.addCase(
      updateUserPost.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.status = true;
        state.addPostloader = false;
        state.addPostError = null;
      }
    );
    builder.addCase(updateUserPost.pending, (state, action) => {
      state.status = false;
      state.addPostloader = true;
      state.addPostError = null;
    });
    builder.addCase(updateUserPost.rejected, (state, action) => {
      state.status = false;
      state.addPostloader = false;
      state.addPostError =
        (action.payload as string) || "Failed to updateUserPost";
    });

    //Delete post---------------------------------------------------------

    builder.addCase(
      deleteUserPost.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.deleteStatus = action.payload.success;
        state.deletePostloader = false;
        state.addPostError = null;
      }
    );
    builder.addCase(deleteUserPost.pending, (state, action) => {
      state.deleteStatus = false;
      state.deletePostloader = true;
      state.addPostError = null;
    });
    builder.addCase(deleteUserPost.rejected, (state, action) => {
      state.deleteStatus = false;
      state.deletePostloader = false;
      state.addPostError =
        (action.payload as string) || "Failed to deleteUserPost";
    });
  },
});

export default addPostsSlice.reducer;
