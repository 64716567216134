import { useDispatch } from "react-redux";
import { AppDispatch } from "../store";
import { warmUpLambda } from "../store/actions/PostAction";
import { useEffect } from "react";

const WarmupController = () => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    warmLambda();
  }, []);

  const warmLambda = async () => {
    dispatch(warmUpLambda());
  };
};
export default WarmupController;
