import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store";
import {
  getAllPosts,
  addPost,
  updateUserPost,
  loadMore,
  fetchUserSpecificPosts,
  deleteUserPost,
  getPostById,
} from "../store/actions/PostAction";
import React from "react";
import { setFilters } from "../store/reducers/PostsSlice";
import { useAuthenticator } from "@aws-amplify/ui-react";

const PostsController = () => {
  const {
    posts,
    myPosts,
    myPostsLoader,
    loader,
    searchFilters,
    mystate,
    postError,
    loadSinlePost,
    postDetails,
    loadSinlePostError,
  } = useSelector((state: RootState) => state.posts);
  const { status, addPostloader, deleteStatus, addPostError } = useSelector(
    (state: RootState) => state.addPost
  );

  React.useEffect(() => {
    // console.log("first time load getAllUserPosts......");
    const newFilters = { ...searchFilters, page: 1 };
    // getAllUserPosts(newFilters);
    // setUserFilters(newFilters);
  }, []);

  React.useEffect(() => {
    // console.log("Fiter changed, reloading....");
    getAllUserPosts(searchFilters);
  }, [searchFilters]);

  interface GetAllPostsParams {
    vt?: string;
    l?: string;
    m?: string;
    s?: string;
    sort?: string;
    page?: number;
  }

  const dispatch = useDispatch<AppDispatch>();

  const getAllUserPosts = async (params: GetAllPostsParams) => {
    dispatch(getAllPosts(params));
  };

  const setUserFilters = (filters: GetAllPostsParams) => {
    dispatch(setFilters(filters));
  };

  const loadMorePosts = async (page: number) => {
    const newPage = page;
    const newFilters = { ...searchFilters, page: newPage };
    dispatch(setFilters(newFilters));
    // await dispatch(loadMore(newFilters));
  };

  const savePost = async (data: any) => {
    await dispatch(addPost(data)).unwrap();
  };

  const updatePost = async (data: any) => {
    await dispatch(updateUserPost(data)).unwrap();
  };

  const deletePost = async (postId: string) => {
    await dispatch(deleteUserPost(postId)).unwrap();
  };

  const fetchMyPosts = async (userId: string) => {
    await dispatch(fetchUserSpecificPosts(userId)).unwrap();
  };
  const fetchPostById = async (postId: string) => {
    dispatch(getPostById(postId));
  };

  return {
    posts,
    loader,
    getAllUserPosts,
    savePost,
    updatePost,
    status,
    addPostloader,
    loadMorePosts,
    searchFilters,
    setUserFilters,
    fetchMyPosts,
    myPosts,
    myPostsLoader,
    deletePost,
    deleteStatus,
    postError,
    addPostError,
    fetchPostById,
    loadSinlePost,
    postDetails,
    loadSinlePostError,
  };
};

export default PostsController;
