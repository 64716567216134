import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store";
import { getPostById } from "../store/actions/PostAction";

const PostsDetailsController = () => {
  const { postDetails } = useSelector((state: RootState) => state.posts);

  const dispatch = useDispatch<AppDispatch>();

  const fetchPostById = async (postId: string) => {
    dispatch(getPostById(postId));
  };

  return {
    fetchPostById,
    postDetails,
  };
};

export default PostsDetailsController;
