import React, { useState } from 'react';
import { Box, TextField, MenuItem, Grid, Button, InputAdornment, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import PostsController from '../controllers/PostsController';

interface GetAllPostsParams {
    vt?: string;
    l?: string;
    m?: string;
    s?: string;
    sort?: string;
    page?: number;
}
interface Location { district: string; citiesTownsAndJunctions: string[]; };
interface SearchBarProps {
    vehicleTypes: string[];
    locations: Location[];
    makes: string[];
    searchFilters: GetAllPostsParams;
    setUserFilters: ({ }) => void
}

const SearchBar: React.FC<SearchBarProps> = ({ vehicleTypes, locations, makes, searchFilters, setUserFilters }) => {
    const [searchTerm, setSearchTerm] = useState(searchFilters.s);
    const [vehicleType, setVehicleType] = useState(searchFilters.vt);
    const [location, setLocation] = useState(searchFilters.l);
    const [make, setMake] = useState(searchFilters.m);

    const handleSearch = () => {
        setUserFilters({
            ...searchFilters,
            vt: vehicleType == "All" ? "" : vehicleType,
            l: location == "All" ? "" : location,
            m: make == "All" ? "" : make,
            s: searchTerm,
            page: 1
        })
    };

    return (
        <Box sx={{ width: '100%', marginTop: 5, marginBottom: 1 }}>
            <Grid container spacing={2} alignItems="center" justifyContent={"space-between"}>
                <Grid item xs={12} sm={6} md={2}>
                    <TextField
                        select
                        fullWidth
                        variant="outlined"
                        label="Vehicle Type"
                        value={vehicleType}
                        onChange={(e) => setVehicleType(e.target.value)}
                    >
                        <MenuItem value="All">
                            <em>All</em>
                        </MenuItem>
                        {vehicleTypes.map((type) => (
                            <MenuItem key={type} value={type}>
                                {type}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                    <TextField
                        select
                        fullWidth
                        variant="outlined"
                        label="Location"
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                    >
                        <MenuItem value="All">
                            <em>All</em>
                        </MenuItem>
                        {locations.map((loc) => (
                            <MenuItem key={loc.district} value={loc.district}>
                                {loc.district}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                    <TextField
                        select
                        fullWidth
                        variant="outlined"
                        label="Make"
                        value={make}
                        onChange={(e) => setMake(e.target.value)}
                    >
                        <MenuItem value="All">
                            <em>All</em>
                        </MenuItem>
                        {makes.map((make) => (
                            <MenuItem key={make} value={make}>
                                {make}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={handleSearch}
                                        sx={{
                                            p: 1,
                                            backgroundColor: 'darkred',
                                            '&:hover': {
                                                backgroundColor: 'red',
                                            },
                                            '&:active': {
                                                backgroundColor: 'lightred',
                                            },
                                            color: 'white', // Ensures the icon color contrasts well with the background
                                        }}
                                    >
                                        <SearchIcon sx={{ fontSize: 30 }} />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}

                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'darkred', // dark and bold inactive border color
                                    borderWidth: '2px', // bold border
                                },
                                '&:hover fieldset': {
                                    borderColor: 'black', // change border color on hover
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: 'black', // change border color when focused
                                },
                            },
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default SearchBar;
