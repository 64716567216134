import React from 'react';
import { Box, Typography, Container, Link } from '@mui/material';
import TopNavBar from '../../components/TopNavBar';
import UserController from '../../controllers/UserController';

const Help: React.FC = () => {
    const { user, signOut, route, authStatus } = UserController()
    return (
        <TopNavBar user={user} signOut={signOut} route={route} authStatus={authStatus} >
            <Box sx={{ backgroundColor: '#f4f4f4', minHeight: '100vh', padding: '2rem 0' }}>
                <Container maxWidth="md">
                    <Typography variant="h4" gutterBottom>
                        Help Center
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Welcome to the Peep.lk Help Center. Here, you can find answers to frequently asked questions and get support for any issues you may encounter while using our platform.
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        Frequently Asked Questions
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        How do I post an ad?
                    </Typography>
                    <Typography variant="body1" paragraph>
                        To post an ad, simply click on the "Place an Ad" button on the homepage and fill in the required details about the vehicle you want to sell. You can upload images and provide a detailed description to attract potential buyers.
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        How do I edit or delete my ad?
                    </Typography>
                    <Typography variant="body1" paragraph>
                        To edit or delete your ad, go to your account dashboard and select the ad you want to modify. You can update the details or remove the ad if it is no longer relevant.
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        How many ads can I post?
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Each user can post up to three active ads at a time. This helps ensure that the platform remains fair and balanced for all users.
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        How do I contact Peep.lk for further assistance?
                    </Typography>
                    <Typography variant="body1" paragraph>
                        If you need further assistance, please don't hesitate to contact us. You can reach us via email at <Link href="mailto:send.peep@gmail.com">send.peep@gmail.com</Link>. We strive to respond to all inquiries within 24 hours.
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        Do you use my personal details for any other purposes?
                    </Typography>
                    <Typography variant="body1" paragraph>
                        No, we do not use your personal details for any other purposes. Your privacy is important to us, and we only use your information to facilitate your interactions on our platform.
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        Still need help?
                    </Typography>
                    <Typography variant="body1" paragraph>
                        If your question is not answered here, feel free to reach out to our support team at <Link href="mailto:send.peep@gmail.com">send.peep@gmail.com</Link>. We are here to help you!
                    </Typography>
                </Container>
            </Box>
        </TopNavBar>
    );
};

export default Help;
