import React, { useEffect } from 'react';
import {
    Authenticator, Theme,
    useTheme,
    useAuthenticator,
    ThemeProvider,
    View
} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';


const Login: React.FC = () => {

    const { tokens } = useTheme();
    const theme: Theme = {
        name: 'Auth Example Theme',
        tokens: {
            components: {
                authenticator: {
                    router: {
                        boxShadow: `0 0 16px ${tokens.colors.overlay['10']}`,
                        borderWidth: '0',
                    },
                    form: {
                        padding: `${tokens.space.medium} ${tokens.space.xl} ${tokens.space.medium}`,
                    },
                },
                button: {
                    primary: {
                        backgroundColor: '#0171eb',
                        _hover: { color: 'white', backgroundColor: 'red' }

                    },
                    link: {
                        color: tokens.colors.purple['80'],
                    },

                },
                tabs: {
                    item: {
                        color: '#0171eb',
                        _active: {
                            borderColor: 'red',
                            color: 'red',
                        },
                    },
                },
            },
        },
    };

    return (
        <ThemeProvider theme={theme}>

            <Authenticator signUpAttributes={['email', 'phone_number']} />

        </ThemeProvider>
    )


};
export default Login;