import { Link, useNavigate } from 'react-router-dom';
import { Typography, AppBar, Box, Toolbar, Stack, Button, makeStyles, useScrollTrigger, CssBaseline, Container, styled } from '@mui/material';
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import * as React from 'react';
import Logo from "../images/logo2.png";
import { useState, useEffect } from 'react';
import {
    AuthUser
} from 'aws-amplify/auth';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Logout from '@mui/icons-material/Logout';
import Book from '@mui/icons-material/Book';
import Menu from '@mui/icons-material/Menu';
import NoAccounts from '@mui/icons-material/NoAccounts';
import AccountBox from '@mui/icons-material/AccountBox';
import { UseAuthenticator, useAuthenticator } from '@aws-amplify/ui-react';
import Footer from './Footer';
import { BootstrapButton } from '../utility/styles';


interface Props {
    user: AuthUser;
    signOut: () => void;
    route: string;
    authStatus: string;
    window?: () => Window;
    children: React.ReactElement;

}

function ElevationScroll(props: Props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

const TopNavBar: React.FC<Props> = (props: Props) => {
    const { user, signOut, route, authStatus, children, window } = props;
    // const { user, signOut } = useAuthenticator((context) => [context.user]);
    // const { route } = useAuthenticator(context => [context.route]);



    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen: boolean) => () => {
        setOpen(newOpen);
    };

    const handleSignOut = async () => {
        try {
            await signOut();
            navigate('/');

            // Optionally, redirect or perform additional actions after sign-out
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    // React.useEffect(() => {
    //     console.log('USER.......', route)
    // }, [route])

    const DrawerList = (
        <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>

            <List>
                {authStatus === 'authenticated' && user && <ListItem>
                    <h2>Welcome, {user.username}!</h2>
                </ListItem>
                }
                <ListItem key={'mypage'} disablePadding>
                    <ListItemButton onClick={() => navigateToMypage()}>
                        <ListItemIcon>
                            <Book />
                        </ListItemIcon>
                        <ListItemText primary={'My page'} />
                    </ListItemButton>
                </ListItem>
            </List>
            <Divider />
            <List>

                {authStatus !== 'authenticated' && <ListItem key={'login'} disablePadding>
                    <ListItemButton onClick={() => { navigate('/login') }} >
                        <ListItemIcon>
                            <Logout />
                        </ListItemIcon>
                        <ListItemText primary={'Login'} />
                    </ListItemButton>
                </ListItem>
                }
                {authStatus === 'authenticated' && <ListItem key={'delete'} disablePadding>
                    <ListItemButton onClick={handleSignOut} disabled={true}>
                        <ListItemIcon>
                            <NoAccounts />
                        </ListItemIcon>
                        <ListItemText primary={'Delete Account'} />
                    </ListItemButton>
                </ListItem>
                }
                {authStatus === 'authenticated' && <ListItem key={'editprofile'} disablePadding>
                    <ListItemButton onClick={handleSignOut} disabled={true}>
                        <ListItemIcon>
                            <AccountBox />
                        </ListItemIcon>
                        <ListItemText primary={'Edit My Profile'} />
                    </ListItemButton>
                </ListItem>
                }
                {authStatus === 'authenticated' && <ListItem key={'signout'} disablePadding>
                    <ListItemButton onClick={handleSignOut}>
                        <ListItemIcon>
                            <Logout />
                        </ListItemIcon>
                        <ListItemText primary={'Sign out'} />
                    </ListItemButton>
                </ListItem>
                }

            </List>
        </Box >
    );

    const navigateToMypage = () => {
        if (authStatus === 'authenticated') {
            navigate('/mypage');
        } else {
            navigate('/login');
        }
    };

    const navigateTo = () => {
        // console.log('route--------------- ', route)
        if (authStatus === 'authenticated') {
            navigate('/postitem', { state: { mode: 'new' } });
        } else {
            navigate('/login');
        }
    };

    return (
        <React.Fragment>
            <CssBaseline />
            <ElevationScroll {...props}>
                <AppBar component="nav" elevation={0} sx={{ boxShadow: 'none', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }} style={{ backgroundColor: '#f6f4f3', justifyContent: 'center', alignItems: 'center' }}>
                    <Box sx={{ width: '100%', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }} >
                        <Box
                            sx={{
                                width: '100%',
                                height: '4px', // Full row height
                                display: 'flex',
                            }}
                        >
                            <Box
                                sx={{
                                    flex: '1 1 20%',
                                    backgroundColor: '#c02219',
                                }}
                            />
                            <Box
                                sx={{
                                    flex: '1 1 10%',
                                    backgroundColor: '#fcadaf',
                                }}
                            />
                            <Box
                                sx={{
                                    flex: '1 1 15%',
                                    backgroundColor: '#daf7a6',
                                }}
                            />
                            <Box
                                sx={{
                                    flex: '1 1 10%',
                                    backgroundColor: '#f52f26',
                                }}
                            />
                            <Box
                                sx={{
                                    flex: '1 1 15%',
                                    backgroundColor: '#bf221e',
                                }}
                            />
                            <Box
                                sx={{
                                    flex: '1 1 5%',
                                    backgroundColor: '#ffb502',
                                }}
                            />
                            <Box
                                sx={{
                                    flex: '1 1 25%',
                                    backgroundColor: '#bf221e',
                                }}
                            />
                        </Box>
                        <Toolbar sx={{
                            width: '100%', // By default, the toolbar takes full width
                            maxWidth: { // Define maximum width for different screen sizes
                                xs: '100%', // For extra small screens, it takes full width
                                sm: '100%', // For small screens, it takes 80% of the width
                                md: '84%',
                                // For medium screens, it takes full width minus 320px
                            },
                            margin: 'auto', // Centers the toolbar horizontally
                            backgroundColor: '#f6f4f3',
                        }} >

                            <Typography
                                variant="h6"
                                component="div"
                                sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' }, color: 'black', }}
                            >
                                <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <Link to="/" style={{
                                        textDecoration: 'none',
                                        display: 'flex'
                                    }} >
                                        <Box
                                            component="img"
                                            sx={{
                                                height: 64,
                                            }}
                                            alt="Your logo."
                                            src={Logo}
                                        />
                                    </Link>
                                </Box>

                            </Typography>

                            <Typography
                                variant="h6"
                                component="div"
                                sx={{ flexGrow: 1, display: { xs: 'block', sm: 'none' }, color: 'black' }}
                            >
                                <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <Link to="/" style={{
                                        textDecoration: 'none',
                                        display: 'flex'
                                    }} >
                                        <Box
                                            component="img"
                                            sx={{
                                                height: 64,
                                            }}
                                            alt="Your logo."
                                            src={Logo}
                                        />
                                    </Link>
                                </Box>
                            </Typography>
                            <Stack direction="row" spacing={2}>
                                {/* <Button variant="outlined" style={{ display: 'block', color: 'white' }} size='large' startIcon={<ControlPointOutlinedIcon fontSize='large' />}>
            </Button> */}
                                <BootstrapButton variant="contained" sx={{
                                    display: { xs: 'none', sm: 'block' },
                                    textTransform: 'capitalize',
                                    backgroundColor: '#0071EB', // Change to your desired background color
                                    '&:hover': {
                                        backgroundColor: '#0093FF', // Change to your desired hover color
                                        color: 'white',
                                    },
                                    height: 35
                                }}

                                    style={{ marginRight: 24 }}
                                    size='large'
                                    onClick={navigateTo}

                                >
                                    <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                                        <ControlPointOutlinedIcon fontSize='small' />
                                        <div style={{ marginLeft: 8 }}>Place an Ad</div>
                                    </div>
                                </BootstrapButton>
                                <Button variant="contained" sx={{
                                    display: { xs: 'block', sm: 'none' },
                                    textTransform: 'capitalize',
                                    backgroundColor: '#0071EB', // Change to your desired background color
                                    '&:hover': {
                                        backgroundColor: '#0093FF', // Change to your desired hover color
                                        color: 'white',
                                    },
                                    height: 35
                                }}
                                    onClick={navigateTo}
                                    style={{ marginRight: 20 }}
                                >
                                    <ControlPointOutlinedIcon fontSize='large' style={{ height: '100%' }} />
                                </Button>
                            </Stack>
                            <Box
                                sx={{ display: 'flex', justifyContent: 'center' }}
                                color={{ xs: "black", sm: "black", md: "black", lg: "black", xl: "black" }}
                            >

                                <Menu onClick={toggleDrawer(true)} fontSize='large' />

                            </Box>

                        </Toolbar>

                    </Box>
                    <Drawer open={open} onClose={toggleDrawer(false)}>
                        {DrawerList}
                    </Drawer>
                </AppBar >
            </ElevationScroll>
            <Toolbar /> {/* To get the gap from top */}
            <Container sx={{ backgroundColor: '#f6f4f3', justifyContent: 'center' }}>

                {children}

            </Container>

            <Footer />

        </React.Fragment >
    );
};


export default TopNavBar;