import React, { useState } from 'react';
import { TextField, MenuItem, Grid, Box } from '@mui/material';
import PostsController from '../controllers/PostsController';

interface GetAllPostsParams {
    vt?: string;
    l?: string;
    m?: string;
    s?: string;
    sort?: string;
    page?: number;
}
interface SortButtonProps {
    searchFilters: GetAllPostsParams;
    setUserFilters: ({ }) => void
}
const SortButton: React.FC<SortButtonProps> = ({ searchFilters, setUserFilters }) => {
    const [sortOption, setSortOption] = useState(searchFilters.sort);

    const handleSortChange = (event: any) => {
        setSortOption(event.target.value);
        // searchParams.sort = event.target.value;
        setUserFilters({ ...searchFilters, sort: event.target.value })
        //getAllUserPosts(searchFilters);
        // Implement sorting logic here based on event.target.value
    };

    return (
        <Box >
            <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        select
                        fullWidth
                        label="Sort By"
                        variant="outlined"
                        value={sortOption}
                        onChange={handleSortChange}
                    >
                        <MenuItem value="priceASC">Price: Low to High</MenuItem>
                        <MenuItem value="priceDESC">Price: High to Low</MenuItem>
                        <MenuItem value="postedDateASC">Date: New to Old</MenuItem>
                        <MenuItem value="postedDateDESC">Date: Old to New</MenuItem>
                    </TextField>
                </Grid>
            </Grid>
        </Box>
    );
}

export default SortButton;
