import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { University } from "../../constants/constants";

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
export const getUniversities = createAsyncThunk<University[], void>(
  "getUniversities",
  async () => {
    try {
      await delay(2000);
      const { data } = await axios.get(
        "http://universities.hipolabs.com/search?country=United+States"
      );
      // console.log(JSON.stringify(data,null,2));
      return data;
    } catch (exception) {
      // console.log(JSON.stringify(exception,null,2));
      // console.log('Fetch error data......')
    }
  }
);
