import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import DetailsPage from './pages/DetailsPage';
import PostItem from './pages/PostItem';
import { store } from './store'
import { Provider } from 'react-redux'
import ErrorPage from './pages/ErrorPage';
import MyPage from './pages/MyPage';
import { Amplify } from "aws-amplify";
import LoginPage from './pages/LoginPage';
import { Authenticator } from '@aws-amplify/ui-react';
import About from './pages/footer/about';
import Terms from './pages/footer/terms';
import Privacy from './pages/footer/privacy';
import Help from './pages/footer/help';
import ReactGA from "react-ga4";
import LandingPage from './pages/LandingPage';
import { Helmet } from 'react-helmet';

ReactGA.initialize("G-FHBRNGERBS");

ReactGA.send({ hitType: "pageview", page: window.location.pathname });

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID!,
      userPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID!,
      loginWith: { // Optional
        oauth: {
          domain: process.env.REACT_APP_domain!,
          scopes: [process.env.REACT_APP_scopes!],
          redirectSignIn: [process.env.REACT_APP_redirectSignIn!],
          redirectSignOut: [process.env.REACT_APP_redirectSignOut!],
          responseType: 'code',
        }

      }
    }
  }
});


const router = createBrowserRouter([
  {
    path: '/', element: <LandingPage />, errorElement: <ErrorPage />
  },
  {
    path: '/ads', element: <App />, errorElement: <ErrorPage />
  },
  {
    path: '/details', element: <DetailsPage />
  },
  {
    path: '/details/:itemid', element: <DetailsPage />
  },
  {
    path: '/postitem', element: <PostItem />
  },
  {
    path: '/mypage', element: <MyPage />
  },
  {
    path: '/login', element: <LoginPage />
  },
  {
    path: '/about', element: <About />
  },
  {
    path: '/terms', element: <Terms />
  },
  {
    path: '/help', element: <Help />
  },
  {
    path: '/privacy', element: <Privacy />
  },


]);




const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    {/* <React.StrictMode> */}
    <Authenticator.Provider>
      <div style={{ backgroundColor: '#f6f4f3' }}>
        {/* <Helmet>
          <title>Peep.lk - Free ads, Buy,Sell,Vehicles in Sri Lanka</title>
          <meta property="og:description" content='Buy and sell second-hand vehicles or brand new ones. Post free ads. Find a great deal near you, or search all over Sri Lanka.' />
          <meta property="og:image:secure" content="https://www.peep.lk/peep2crop.png" />
          <meta property="og:image:type" content="image/png" />
          <meta property="og:image:width" content="300" />
          <meta property="og:image:height" content="300" />
          <meta property="og:url" content="https://www.peep.lk/ads" />
          <meta property="og:type" content="website" />
        </Helmet> */}
        <RouterProvider router={router} />
      </div>

    </Authenticator.Provider>
    {/* <App /> */}
    {/* </React.StrictMode> */}
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
