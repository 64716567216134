import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  getAllPosts,
  loadMore,
  fetchUserSpecificPosts,
  getPostById,
} from "../actions/PostAction";
import { ApiResponse } from "../../constants/constants";

interface PostState {
  myPosts: ApiResponse;
  myPostsLoader: boolean;
  posts: ApiResponse;
  loader: boolean;
  searchFilters: Filters;
  mystate: string;
  postError: string | null;
  postDetails: any;
  loadSinlePost: boolean;
  loadSinlePostError: string | null;
}

interface Filters {
  vt?: string;
  l?: string;
  m?: string;
  s?: string;
  sort?: string;
  page?: number;
}

// Initial state
const initialState: PostState = {
  posts: { data: [], message: "", success: false, total: 0, last_page: 0 },
  myPosts: {
    data: [],
    message: "",
    success: false,
    total: 0,
    last_page: 0,
  },
  myPostsLoader: false,
  loader: false,
  searchFilters: {
    vt: "",
    l: "",
    m: "",
    s: "",
    sort: "",
    page: 1,
  },
  mystate: "chamila",
  postError: null,
  postDetails: null,
  loadSinlePost: false,
  loadSinlePostError: null,
};

export const postsSlice = createSlice({
  name: "GET_POSTS",
  initialState: initialState,
  reducers: {
    setFilters: (state, action: PayloadAction<Filters>) => {
      state.searchFilters = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getAllPosts.fulfilled,
      (state, action: PayloadAction<ApiResponse>) => {
        // Concatenate the new data with the existing data
        state.posts.data = action.payload.data;
        state.posts.total = action.payload.total;
        state.posts.last_page = action.payload.last_page;
        state.loader = false;
        state.postError = null;
      }
    );
    builder.addCase(getAllPosts.pending, (state, action) => {
      state.loader = true;
      state.postError = null;
    });
    builder.addCase(getAllPosts.rejected, (state, action) => {
      state.loader = false;
      state.postError = (action.payload as string) || "Failed to getAllposts";
    });

    //-------------------------------------------------

    builder.addCase(
      loadMore.fulfilled,
      (state, action: PayloadAction<ApiResponse>) => {
        // Concatenate the new data with the existing data
        state.posts.data = action.payload.data;
        state.posts.total = action.payload.total;
        state.posts.last_page = action.payload.last_page;
        state.loader = false;
        state.postError = null;
      }
    );
    builder.addCase(loadMore.pending, (state, action) => {
      state.loader = true;
      state.postError = null;
    });
    builder.addCase(loadMore.rejected, (state, action) => {
      state.loader = false;
      state.postError = (action.payload as string) || "Failed to loadMore";
    });

    //-------------------------------------------------
    // Fetch user specific posts
    //-------------------------------------------------
    builder.addCase(
      fetchUserSpecificPosts.fulfilled,
      (state, action: PayloadAction<ApiResponse>) => {
        state.myPosts.data = action.payload.data;
        state.myPosts.total = action.payload.total;
        state.myPosts.last_page = action.payload.last_page;
        state.myPostsLoader = false;
        state.postError = null;
      }
    );
    builder.addCase(fetchUserSpecificPosts.pending, (state, action) => {
      state.myPostsLoader = true;
      state.postError = null;
    });
    builder.addCase(fetchUserSpecificPosts.rejected, (state, action) => {
      state.myPostsLoader = false;
      state.postError =
        (action.payload as string) || "Failed to fetchUserSpecificPosts";
    });

    //Get one post---------------------------------------------------------

    builder.addCase(
      getPostById.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loadSinlePost = true;
        state.postDetails = action.payload.data;
        state.loadSinlePostError = null;
      }
    );
    builder.addCase(getPostById.pending, (state, action) => {
      state.loadSinlePost = true;
      state.loadSinlePostError = null;
      state.postDetails = null;
    });
    builder.addCase(getPostById.rejected, (state, action) => {
      state.loadSinlePost = false;
      state.postDetails = null;
      state.loadSinlePostError =
        (action.payload as string) || "Failed to getPostById";
    });
  },
});

export const { setFilters } = postsSlice.actions;
export default postsSlice.reducer;
