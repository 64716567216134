export const previousOwnersArray = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const CAR_BRANDS: string[] = [
  "Toyota",
  "Honda",
  "Ford",
  "Chevrolet",
  "Nissan",
  "BMW",
  "Mercedes-Benz",
  "Volkswagen",
  "Audi",
  "Hyundai",
  "Kia",
  "Subaru",
  "Mazda",
  "Dodge",
  "Jeep",
  "Lexus",
  "Tesla",
  "Volvo",
  "Jaguar",
  "Land Rover",
  "Porsche",
  "Mitsubishi",
  "Fiat",
  "Ferrari",
  "Lamborghini",
  "Bentley",
  "Rolls-Royce",
  "Aston Martin",
  "Maserati",
  "Bugatti",
  "Peugeot",
  "Renault",
  "Citroën",
  "Alfa Romeo",
  "Infiniti",
  "Acura",
  "Lincoln",
  "Cadillac",
  "Buick",
  "Chrysler",
  "GMC",
  "Ram",
  "Mini",
  "Suzuki",
  "Isuzu",
  "Saab",
  "Skoda",
  "Seat",
  "Opel",
  "Genesis",
  "Perodua",
  "Other",
];

export const LOCATIONS_OLD: string[] = [
  "Ampara",
  "Anuradhapura",
  "Badulla",
  "Batticaloa",
  "Colombo",
  "Galle",
  "Gampaha",
  "Hambantota",
  "Jaffna",
  "Kalutara",
  "Kandy",
  "Kegalle",
  "Kilinochchi",
  "Kurunegala",
  "Mannar",
  "Matale",
  "Matara",
  "Moneragala",
  "Mullaitivu",
  "Nuwara Eliya",
  "Polonnaruwa",
  "Puttalam",
  "Ratnapura",
  "Trincomalee",
  "Vavuniya",
];

export const VEHICLE_TYPES = [
  "Car",
  "Jeep",
  "Cab",
  "Van",
  "Bus",
  "Lorry",
  "Bicycle",
  "Motorcycle",
  "Mopad",
  "Scooter",
  "Three-wheeler",
  "Convertible",
  "Minivan",
  "Truck",
  "Ship/Boat",
];

export const LOCATIONS = [
  {
    district: "Colombo",
    citiesTownsAndJunctions: [
      "Colombo",
      "Dehiwala-Mount Lavinia",
      "Moratuwa",
      "Kotte",
      "Battaramulla",
      "Piliyandala",
      "Homagama",
      "Maharagama",
      "Nugegoda",
      "Boralesgamuwa",
      "Kesbewa",
      "Angoda",
      "Kaduwela",
      "Malabe",
      "Kolonnawa",
      "Ratmalana",
      "Hendala",
      "Thalawathugoda",
      "Rajagiriya",
      "Pannipitiya",
      "Wellawatte",
      "Pamunugama",
      "Athurugiriya",
      "Peliyagoda",
      "Udahamulla",
      "Kollupitiya",
      "Bambalapitiya",
      "Thimbirigasyaya",
      "Nawala",
      "Kirulapone",
      "Kotahena",
      "Maradana",
      "Slave Island",
      "Fort",
      "Mutwal",
      "Kirimandala Mawatha",
      "Narahenpita",
      "Kirillapone",
      "Orugodawatta",
      "Dematagoda",
      "Havelock Town",
      "Wellewatte",
      "Mattakkuliya",
      "Grandpass",
    ],
  },
  {
    district: "Gampaha",
    citiesTownsAndJunctions: [
      "Gampaha",
      "Negombo",
      "Kelaniya",
      "Ragama",
      "Wattala",
      "Kiribathgoda",
      "Ja-Ela",
      "Minuwangoda",
      "Divulapitiya",
      "Seeduwa",
      "Veyangoda",
      "Nittambuwa",
      "Mirigama",
      "Katunayake",
      "Ganemulla",
      "Kadawatha",
      "Yakkala",
      "Ekala",
      "Kirindiwela",
      "Biyagama",
      "Henegama",
      "Malwathuhiripitiya",
      "Kalagedihena",
      "Pugoda",
      "Mabola",
      "Gonawala",
      "Udugampola",
      "Mudalapali",
      "Dalupotha",
      "Kochchikade",
      "Mahabage",
      "Heenatiyana",
      "Weliweriya",
      "Thudella",
      "Welisara",
      "Kapuwatte",
      "Rilaulla",
    ],
  },
  {
    district: "Kalutara",
    citiesTownsAndJunctions: [
      "Kalutara",
      "Panadura",
      "Beruwala",
      "Horana",
      "Aluthgama",
      "Matugama",
      "Wadduwa",
      "Bandaragama",
      "Agalawatta",
      "Ingiriya",
      "Dodangoda",
      "Payagala",
      "Maggona",
      "Bombuwala",
      "Pothuhera",
      "Dharga Town",
      "Mathugama",
      "Mahagama",
      "Nalluruwa",
      "Mahawila",
      "Molligoda",
      "Gonaduwa",
      "Waskaduwa",
      "Kethhena",
      "Hettimulla",
      "Nugagoda",
      "Nape",
      "Moronthuduwa",
      "Kanda Vihara",
      "Puwakdanda",
      "Galpatha",
    ],
  },
  {
    district: "Kandy",
    citiesTownsAndJunctions: [
      "Kandy",
      "Gampola",
      "Nawalapitiya",
      "Katugastota",
      "Peradeniya",
      "Ampitiya",
      "Kundasale",
      "Digana",
      "Wattegama",
      "Akurana",
      "Pilimathalawa",
      "Galagedara",
      "Kadugannawa",
      "Hanguranketha",
      "Talawakelle",
      "Madawala",
      "Deltota",
      "Pussellawa",
      "Menikdiwela",
      "Thumpane",
      "Teldeniya",
      "Hewaheta",
      "Watapuluwa",
      "Kurunegala Road",
      "Weligalla",
      "Nittawela",
      "Tennekumbura",
      "Hanthana",
      "Yatinuwara",
      "Katugasthota Town",
      "Udawattekele",
      "Gatambe",
    ],
  },
  {
    district: "Matale",
    citiesTownsAndJunctions: [
      "Matale",
      "Dambulla",
      "Sigiriya",
      "Galewela",
      "Rattota",
      "Ukuwela",
      "Pallepola",
      "Naula",
      "Yatawatta",
      "Laggala-Pallegama",
      "Wilgamuwa",
      "Ambanganga Korale",
      "Nalanda",
      "Kibissa",
      "Palapathwala",
      "Kaikawala",
      "Opalgala",
      "Madawala Ulpotha",
      "Mahawela",
      "Kalundawa",
      "Madipola",
      "Pubbogama",
      "Ibbagamuwa",
      "Yapahuwa",
      "Ehelepola",
      "Inamaluwa",
      "Pitawala",
      "Rangiri Dambulla",
      "Weheragala",
      "Makuliyadda",
    ],
  },
  {
    district: "Nuwara Eliya",
    citiesTownsAndJunctions: [
      "Nuwara Eliya",
      "Hatton",
      "Talawakelle",
      "Haputale",
      "Ginigathena",
      "Ramboda",
      "Kotagala",
      "Maskeliya",
      "Nanu Oya",
      "Pussellawa",
      "Ragala",
      "Walapane",
      "Labookellie",
      "Lindula",
      "Agarapathana",
      "Kandapola",
      "Rozella",
      "Dayagama",
      "Watawala",
      "Norwood",
      "Bogawantalawa",
      "Pundaluoya",
      "Keppetipola",
      "Maturata",
      "Norwood Town",
      "Kikiliyamana",
      "Patana",
      "Rathkinda",
      "Wanaraja",
    ],
  },
  {
    district: "Matara",
    citiesTownsAndJunctions: [
      "Matara",
      "Weligama",
      "Akuressa",
      "Deniyaya",
      "Hakmana",
      "Dikwella",
      "Kamburupitiya",
      "Mirissa",
      "Thihagoda",
      "Gandara",
      "Polhena",
      "Mawella",
      "Kottegoda",
      "Malimbada",
      "Yatiyana",
      "Athuraliya",
      "Pitabeddara",
      "Welpita",
      "Devinuwara",
      "Miriswatta",
      "Kekanadura",
      "Wanduramba",
      "Makandura",
      "Nupe",
      "Walgama",
      "Meddawatta",
      "Beliwatta",
      "Kotapola",
      "Hakmana Town",
    ],
  },
  {
    district: "Hambantota",
    citiesTownsAndJunctions: [
      "Hambantota",
      "Tangalle",
      "Beliatta",
      "Kirinda",
      "Sooriyawewa",
      "Puhulwella",
      "Kataragama",
      "Mulkirigala",
      "Debarawewa",
      "Katuwana",
      "Kahandamodara",
      "Dikwella",
      "Ranna",
      "Matara",
      "Ambalantota",
      "Ruhuna",
      "Suriyawewa",
      "Buddhangala",
      "Madurata",
      "Malalewawa",
      "Tissamaharama",
      "Weeraketiya",
    ],
  },
  {
    district: "Jaffna",
    citiesTownsAndJunctions: [
      "Jaffna",
      "Chavakachcheri",
      "Valvettithurai",
      "Point Pedro",
      "Tellippalai",
      "Kopay",
      "Manipay",
      "Karainagar",
      "Nallur",
      "Kachchaveli",
      "Chunnakam",
      "Elephant Pass",
      "Kankesanthurai",
      "Nelliyadi",
      "Puttur",
      "Kayts",
      "Sandilipay",
      "Uduvil",
      "Vadamarchchi",
      "Chulipuram",
      "Mirusuvil",
      "Thirunelveli",
      "Kantharodai",
      "Nedunkerni",
      "Velanai",
      "Vattakachchi",
      "Paranthan",
    ],
  },
  {
    district: "Kilinochchi",
    citiesTownsAndJunctions: [
      "Kilinochchi",
      "Mullaitivu",
      "Elephant Pass",
      "Kokavil",
      "Thunukkai",
      "Nedunkerni",
      "Visuvamadu",
      "Mankulam",
      "Kanakarayankulam",
      "Mulliyawalai",
      "Lankapatuna",
      "Vavuniya North",
      "Poonakary",
      "Akkarayankulam",
      "Pallai",
      "Adampan",
      "Kandavalai",
      "Mirusuvil",
      "Vavuniya South",
    ],
  },
  {
    district: "Vavuniya",
    citiesTownsAndJunctions: [
      "Vavuniya",
      "Vavuniya North",
      "Vavuniya South",
      "Chettikulam",
      "Omanthai",
      "Koviladi",
      "Pallavarayan",
      "Mannakandal",
      "Vengalacheddikulam",
      "Kobai",
      "Kovilkulam",
      "Mulliyawalai",
      "Poonakary",
      "Akkarayankulam",
      "Pallai",
      "Vannipuram",
      "Thirunelveli",
      "Kanagarayankulam",
      "Ponnambalam",
    ],
  },
  {
    district: "Anuradhapura",
    citiesTownsAndJunctions: [
      "Anuradhapura",
      "Mihintale",
      "Kekirawa",
      "Habarana",
      "Dambulla",
      "Kuranagama",
      "Thambuththegama",
      "Mahailluppallama",
      "Rambewa",
      "Ipalogama",
      "Talawa",
      "Horowpothana",
      "Mundalama",
      "Padaviya",
      "Kahatagasdigiliya",
      "Medawachchiya",
      "Nuwarawewa",
      "Mahawilachchiya",
      "Tissapura",
      "Maradankadawala",
      "Manampitiya",
      "Akkarawatta",
      "Kiribathkumbura",
      "Namal Oya",
    ],
  },
  {
    district: "Polonnaruwa",
    citiesTownsAndJunctions: [
      "Polonnaruwa",
      "Maha Oya",
      "Kandalama",
      "Menikdena",
      "Kaduruwela",
      "Lankapura",
      "Giritale",
      "Galkiriyagama",
      "Kawudulla",
      "Dimbulagala",
      "Kalametiya",
      "Angunukolapelessa",
      "Hingurakgoda",
      "Padaviya",
      "Medirigiriya",
      "Anuradhapura",
      "Puliyankulama",
      "Rajanganaya",
      "Vellaikkarane",
    ],
  },
  {
    district: "Batticaloa",
    citiesTownsAndJunctions: [
      "Batticaloa",
      "Kali Oya",
      "Kaluwankerny",
      "Valaichchenai",
      "Manmunai",
      "Kokkaddicholai",
      "Samanthurai",
      "Chenkaladi",
      "Eravur",
      "Paddiruppu",
      "Vavunathivu",
      "Thirukkovil",
      "Karadiyanaru",
      "Kattankudy",
      "Navatkuli",
      "Kiran",
      "Kokkadicholai",
      "Vaharai",
    ],
  },
  {
    district: "Trincomalee",
    citiesTownsAndJunctions: [
      "Trincomalee",
      "Koneswaram",
      "Nilaveli",
      "Sampur",
      "Kalkudah",
      "Kantale",
      "Maha Oya",
      "Muttur",
      "Verugal",
      "Kumburupiddy",
      "Kantalai",
      "Kuchchaveli",
      "Padaviya",
      "Seruwila",
      "Paddiruppu",
      "Navy Camp",
      "Pulmoddai",
      "Thambalagamuwa",
      "Nagadeepa",
      "Kinniya",
      "Ralkut",
    ],
  },
  {
    district: "Ampara",
    citiesTownsAndJunctions: [
      "Ampara",
      "Padiyathalawa",
      "Kalmunai",
      "Samanthurei",
      "Pottuvil",
      "Sinthamani",
      "Uhana",
      "Akkaraipattu",
      "Kalkudah",
      "Sampur",
      "Kalametiya",
      "Pothuvil",
      "Mahiyanganaya",
      "Damana",
      "Addalachchenai",
      "Chenkalady",
      "Thirukkovil",
      "Maha Oya",
      "Thambalapitiya",
      "Maradamadu",
    ],
  },
  {
    district: "Badulla",
    citiesTownsAndJunctions: [
      "Badulla",
      "Ella",
      "Haputale",
      "Welimada",
      "Uva Paranagama",
      "Hali Ela",
      "Bopaththalawa",
      "Buddhagama",
      "Okkampitiya",
      "Galgamuwa",
      "Kundasale",
      "Meegahakiula",
      "Soranathota",
      "Koslanda",
      "Pitakotte",
      "Mahiyanganaya",
      "Nugawela",
      "Kumburupitiya",
      "Gampola",
      "Mahiyangana",
      "Sita Eliya",
    ],
  },
  {
    district: "Monaragala",
    citiesTownsAndJunctions: [
      "Monaragala",
      "Badalkumbura",
      "Bibile",
      "Kataragama",
      "Kebilitta",
      "Panama",
      "Wellawaya",
      "Passara",
      "Medagama",
      "Kumbukkandanwela",
      "Diyathalawa",
      "Puhulwella",
      "Ambagahawewa",
      "Samantha",
      "Balangoda",
      "Balaharuwa",
      "Godakawela",
      "Padiyathalawa",
      "Lunugala",
    ],
  },
  {
    district: "Galle",
    citiesTownsAndJunctions: [
      "Galle",
      "Hikkaduwa",
      "Ambalangoda",
      "Balapitiya",
      "Bentota",
      "Unawatuna",
      "Weligama",
      "Matara",
      "Dikwella",
      "Tangalle",
      "Ahangama",
      "Gintota",
      "Elpitiya",
      "Nagoda",
      "Karapitiya",
      "Neluwa",
      "Akmeemana",
    ],
  },
  {
    district: "Kurunegala",
    citiesTownsAndJunctions: [
      "Kurunegala",
      "Maho",
      "Dambulla",
      "Hettipola",
      "Polgahawela",
      "Alawwa",
      "Kegalle",
      "Wariyapola",
      "Kuliyapitiya",
      "Melsiripura",
      "Pannala",
      "Kahawatta",
      "Kandekumbura",
      "Giriulla",
      "Ibbagamuwa",
      "Nikaweratiya",
      "Panduwasnuwara",
      "Narammala",
      "Ridigama",
      "Mawanella",
      "Galgamuwa",
      "Naththandiya",
    ],
  },
  {
    district: "Kegalle",
    citiesTownsAndJunctions: [
      "Kegalle Town",
      "Mawanella",
      "Rambukkana",
      "Warakapola",
      "Aranayake",
      "Deraniyagala",
      "Gelioya",
      "Nawalapitiya",
      "Ruwanwella",
      "Yatiyantota",
      "Galgamuwa",
      "Hatharaliyadda",
      "Bopagoda",
      "Kahawatta",
      "Pallekele",
      "Mawathagama",
      "Ibbagamuwa",
      "Wewaldeniya",
      "Mabima",
    ],
  },
  {
    district: "Mannar",
    citiesTownsAndJunctions: [
      "Mannar",
      "Mannar Town",
      "Madu",
      "Nanattan",
      "Vankalai",
      "Musali",
      "Pallimunai",
      "Thirukkovil",
      "Adampan",
      "Mulliyawalai",
      "Kalladi",
      "Puttalam",
      "Sella Kataragama",
      "Kondavil",
      "Pallai",
      "Kokilai",
      "Uyangoda",
      "Maddawachchiya",
    ],
  },
  {
    district: "Mullaitivu",
    citiesTownsAndJunctions: [
      "Mullaitivu",
      "Kilinochchi",
      "Poonakary",
      "Thunukkai",
      "Nedunkerni",
      "Visuvamadu",
      "Mankulam",
      "Kanakarayankulam",
      "Mulliyawalai",
      "Lankapatuna",
      "Adampan",
      "Kandavalai",
      "Mirusuvil",
      "Vavuniya North",
      "Vavuniya South",
      "Pulmoddai",
      "Kokavil",
      "Pallai",
      "Elephant Pass",
    ],
  },
  {
    district: "Puttalam",
    citiesTownsAndJunctions: [
      "Puttalam",
      "Chilaw",
      "Kurunegala",
      "Mundalama",
      "Anamaduwa",
      "Kekirawa",
      "Nattandiya",
      "Pallama",
      "Marawila",
      "Kattuwa",
      "Kalametiya",
      "Thalawila",
      "Villusawella",
      "Madurankuliya",
      "Kurumankadu",
      "Arachchikattuwa",
      "Dambadeniya",
      "Mannakkulama",
      "Mangalagama",
      "Uda Walawe",
      "Beruwala",
      "Kalpitiya",
      "Katupotha",
      "Rambewa",
      "Hettipola",
      "Ibbagamuwa",
      "Udappuwa",
      "Kandakkuliya",
      "Dummalasuriya",
      "Wilpattu",
      "Kandatholuwa",
    ],
  },
  {
    district: "Ratnapura",
    citiesTownsAndJunctions: [
      "Ratnapura",
      "Balangoda",
      "Ella",
      "Opanayaka",
      "Kiriella",
      "Kuruwita",
      "Haputale",
      "Thangapara",
      "Ratnapura Town",
      "Ayagama",
      "Narangoda",
      "Pallekele",
      "Mawanella",
      "Elapatha",
      "Pitawala",
      "Puhulpola",
      "Wewaldeniya",
      "Ruwanwella",
      "Pelmadulla",
      "Agalawatte",
      "Bopaththalawa",
      "Beliatta",
      "Kelani Valley",
      "Lellopitiya",
      "Udawalawe",
      "Ginigathena",
      "Mawathagama",
      "Nawagamuwa",
      "Pinnawala",
    ],
  },
];
