import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, CssBaseline, Typography, Grid, Paper, Button, IconButton, CircularProgress, Backdrop } from '@mui/material';
import TopNavBar from '../components/TopNavBar';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { isMobile } from 'react-device-detect'; // Package to detect mobile devices
import { useAuthenticator } from '@aws-amplify/ui-react';
import UserController from '../controllers/UserController';
import PostsController from '../controllers/PostsController';
import { formatNumberWithThousandSeparators } from '../utility/utils';
import PostsDetailsController from '../controllers/PostsDetailsController';
import ShareComponent from '../components/Share';
import { Helmet } from 'react-helmet';

const DetailsPage: React.FC = () => {
    const { user, signOut, route, authStatus } = UserController()
    const { postDetails, fetchPostById } = PostsDetailsController();
    const params = useParams<{ itemid: string }>();
    const [post, setPost] = useState<any>(null); // State to store the post
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [loading, setLoading] = useState(false); // State to track loading

    useEffect(() => {
        const postData = localStorage.getItem('postDetails');
        if (postData == null) {
            fetchPostById(params.itemid!);
        }
    }, []);

    useEffect(() => {
        const postData = localStorage.getItem('postDetails');
        if (postData == null) {
            setPost(postDetails);
        }

    }, [postDetails]);

    useEffect(() => {
        setLoading(true);
        // Retrieve post data from localStorage
        const postData = localStorage.getItem('postDetails');

        if (postData) {
            const post = JSON.parse(postData);
            if (post.postId.toString() === params.itemid) {
                setPost(post);
                setLoading(false);
            } else {
                setPost(null); // Post ID mismatch, reset the post
                setLoading(false);
            }
        } else {
            setPost(null);
            setLoading(false);
        }
        // Update loading state

    }, [params.itemid]);

    const handlePreviousImage = () => {
        if (post) {
            setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? post.imagePaths.length - 1 : prevIndex - 1));
        }
    };

    const handleNextImage = () => {
        if (post) {
            setCurrentImageIndex((prevIndex) => (prevIndex === post.imagePaths.length - 1 ? 0 : prevIndex + 1));
        }
    };

    const handleCopyEmail = () => {
        if (post?.contactInfo?.email) {
            copyToClipboard(post.contactInfo.email);
        }
    };

    const handleCopyPhone = () => {
        if (post?.contactInfo?.phone) {
            copyToClipboard(post.contactInfo.phone);
        }
    };

    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text)
            .then(() => console.log(`Copied ${text} to clipboard`))
            .catch((err) => console.error('Failed to copy:', err));
    };

    const handleContactSeller = () => {
        if (post?.contactInfo?.phone) {
            const phoneNumber = post.contactInfo.phone;
            const whatsappURL = isMobile ?
                `https://wa.me/+94${phoneNumber}` :
                `https://web.whatsapp.com/send?phone=+94${phoneNumber}`;
            window.open(whatsappURL, '_blank');
        }
    };

    if (loading) {
        return (
            <Backdrop open={true} style={{ backgroundColor: 'transparent' }}>
                <CircularProgress style={{ color: '#FF0000' }} size={100} />
            </Backdrop>
        );
    }

    if (!post) {
        return (
            <Backdrop open={true} style={{ backgroundColor: 'transparent' }}>
                <CircularProgress style={{ color: '#FF0000' }} size={100} />
            </Backdrop>
        );
    }

    const itemLocation = post.location + (post.city == "" ? "" : "," + post.city)

    return (

        <TopNavBar user={user} signOut={signOut} route={route} authStatus={authStatus} >
            <Box sx={{ display: 'flex', width: '100%' }}>
                <CssBaseline />
                <Box sx={{ margin: '0 auto', width: '100%', }}>
                    <Typography variant="h4" gutterBottom sx={{
                        fontSize: { xs: 16, sm: 20, md: 24, lg: 28, xl: 32 },
                        mt: { xs: 2, sm: 3, md: 4, lg: 3, xl: 4 },
                        mb: { xs: 2, sm: 3, md: 4, lg: 3, xl: 4 },
                        paddingRight: 2
                    }}> {post.title}</Typography>
                    <Typography variant="h2" gutterBottom sx={{
                        fontSize: { xs: 16, sm: 20, md: 24, lg: 36, xl: 32 },
                        mt: { xs: 2, sm: 3, md: 4, lg: 3, xl: 4 },
                        mb: { xs: 2, sm: 3, md: 4, lg: 3, xl: 4 },
                        fontWeight: 'bold'
                    }}>{"Rs. " + formatNumberWithThousandSeparators(post.price)}</Typography>
                    <Box sx={{ position: 'relative', width: '100%', marginBottom: '16px', overflow: 'hidden', borderRadius: '8px' }}>
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <img
                                src={post.imagePaths[currentImageIndex]}
                                alt={`Car Image ${currentImageIndex + 1}`}
                                style={{ width: '100%', height: '60%', objectFit: 'cover' }}
                            />
                        </Box>

                        <IconButton
                            onClick={handlePreviousImage}
                            sx={{ position: 'absolute', top: '50%', left: '40px', transform: 'translateY(-50%)', zIndex: 1, backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                        >
                            <ArrowBackIosIcon sx={{ color: 'white' }} />
                        </IconButton>
                        <IconButton
                            onClick={handleNextImage}
                            sx={{ position: 'absolute', top: '50%', right: '40px', transform: 'translateY(-50%)', zIndex: 1, backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                        >
                            <ArrowForwardIosIcon sx={{ color: 'white' }} />
                        </IconButton>
                        <Typography
                            variant="caption"
                            sx={{ position: 'absolute', bottom: '8px', left: '50%', transform: 'translateX(-50%)', backgroundColor: 'rgba(0, 0, 0, 0.5)', color: 'white', padding: '2px 4px', borderRadius: '4px' }}
                        >
                            {currentImageIndex + 1} / {post.imagePaths.length}
                        </Typography>
                    </Box>
                    <Paper sx={{ p: 2, mt: 2 }}>
                        <Grid container spacing={0} direction="row">
                            <Grid item xs={12} sm={7}>
                                <Typography variant="h6">Description</Typography>
                                <Typography variant="body1" style={{ marginTop: 20 }} sx={{ whiteSpace: 'pre-line' }}>{post.description}</Typography>
                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: 10, marginBottom: 10 }}>
                                    <ShareComponent id={params.itemid!} />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={5} sx={{ justifyContent: 'center' }}>
                                <Grid item xs={12}>
                                    <Grid item xs={12} sm={12} sx={{ backgroundColor: '#F5F5F5', padding: 3 }}>
                                        <Typography><strong>Make:</strong> {post.make}</Typography>
                                        <Typography><strong>Model:</strong> {post.model}</Typography>
                                        <Typography><strong>Year of Manufacture:</strong> {new Date(post.yom).getFullYear()}</Typography>
                                        <Typography><strong>Year of Registration:</strong> {new Date(post.yor).getFullYear()}</Typography>
                                        <Typography><strong>Mileage:</strong> {post.mileage} km</Typography>
                                        <Typography><strong>Previous Owners:</strong> {post.previosOwners}</Typography>
                                        <Typography><strong>Location:</strong> {itemLocation}</Typography>
                                        <Typography><strong>Price:</strong>{" Rs. " + formatNumberWithThousandSeparators(post.price)}</Typography>
                                        <Typography><strong>Urgency:</strong> {post.urgency ? "Urgent" : "Not Urgent"}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} sx={{ backgroundColor: '#303841', padding: 3, color: 'white' }}>
                                        <Typography variant="h6" sx={{ mt: 2 }}>Contact Information</Typography>
                                        <Typography><strong>Name:</strong> {post.contactInfo.name == "guest" ? "****" : "****"}</Typography>
                                        <Typography sx={{ display: 'flex', }}>
                                            <strong>Phone: {" " + post.contactInfo.phone}{' '}</strong>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                onClick={handleCopyPhone}
                                                sx={{ fontSize: '0.75rem', marginLeft: 2, color: '#23d366', borderColor: '#23d366' }}
                                            >
                                                Copy
                                            </Button>
                                        </Typography>
                                        {post.contactInfo.email && (
                                            <Typography sx={{ display: 'flex', }}>
                                                <strong>Email:</strong> {post.contactInfo.email}{' '}
                                                <Button
                                                    variant="outlined"
                                                    size="small"
                                                    onClick={handleCopyEmail}
                                                    sx={{ fontSize: '0.75rem', marginLeft: 2, color: '#23d366', borderColor: '#23d366' }}
                                                >
                                                    Copy
                                                </Button>
                                            </Typography>
                                        )}
                                        <Button variant="contained" style={{ backgroundColor: '#25D366' }} sx={{ mt: 2 }} onClick={handleContactSeller}>WhatsApp Seller</Button>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Box>
            </Box>
        </TopNavBar>

    );
};

export default DetailsPage;
