import React, { useEffect } from 'react';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import Login from '../components/Login';
import { Box } from '@mui/material';
import Logo from "../images/logo2.png";
import { useNavigate } from 'react-router-dom';
import {
    useAuthenticator,
} from '@aws-amplify/ui-react';

const LoginPage: React.FC = () => {
    const { route } = useAuthenticator(context => [context.route]);
    const navigate = useNavigate();

    useEffect(() => {
        if (route === 'authenticated') {
            navigate('/')
        }

    })

    return (

        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh', // Full viewport height
                width: '100vw',  // Full viewport width
                textAlign: 'left', // Center text alignment
                padding: 2 // Optional: add padding if needed
            }}
        >
            <Box
                component="img"
                sx={{
                    height: 100,
                    paddingRight: 5
                }}
                src={Logo}
                onClick={() => navigate('/')}
            />
            <Login />
        </Box>

    );
};

export default LoginPage;
