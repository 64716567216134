import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import SkeletonItem from './SkeletonItem';

const SkeletonLoader: React.FC = () => {
    return (
        <div >
            <SkeletonItem />
            <SkeletonItem />
            <SkeletonItem />
            <SkeletonItem />
        </div>
    );
};

export default SkeletonLoader;
