import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { getUniversities } from "../actions/UniversitiesAction";
import { University } from "../../constants/constants";

interface UniversitiesState {
    universities: University[];
    loader: boolean;
  }
  
  // Initial state
  const initialState: UniversitiesState = {
    universities: [],
    loader: false
  };

export const universitiesSlice = createSlice({
    name: "universities",
    initialState: initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getUniversities.fulfilled, (state, action:PayloadAction<University[]>) => {
            state.universities = []
            state.universities = action.payload
            state.loader = false
        });
        builder.addCase(getUniversities.pending, (state, action) => {
            state.universities = []
            state.loader =true
        })
        builder.addCase(getUniversities.rejected, (state, action) => {
            state.loader = false
            state.universities = []
        })
    }
})

export default universitiesSlice.reducer;