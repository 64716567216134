import { Box, Tooltip } from "@mui/material";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

export const Urgent = () => {

    return (
        <Tooltip title="Urgent">
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 24,
                height: 24,
                borderRadius: '50%',
                backgroundColor: '#ffe0b2',
                color: 'white',
            }}>
                <PriorityHighIcon fontSize="small" />
            </Box>
        </Tooltip>
    );
}