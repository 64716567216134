import React from 'react';
import { Box, Typography, Container } from '@mui/material';
import TopNavBar from '../../components/TopNavBar';
import UserController from '../../controllers/UserController';


const About: React.FC = () => {
    const { user, signOut, route, authStatus } = UserController()
    return (
        <TopNavBar user={user} signOut={signOut} route={route} authStatus={authStatus} >
            <Box sx={{ backgroundColor: '#f4f4f4', minHeight: '100vh', padding: '2rem 0' }}>
                <Container maxWidth="md">
                    <Typography variant="h4" gutterBottom>
                        About Peep.lk
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Welcome to Peep.lk, your go-to platform for buying and selling vehicles. Whether you're looking to purchase your next vehicle or sell your current one, Peep.lk provides a seamless and user-friendly experience.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        On Peep.lk, users can post ads for vehicles they wish to sell, complete with detailed descriptions and images. Each user can add up to three posts, giving you the flexibility to manage multiple listings.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Our platform allows users to:
                    </Typography>
                    <ul>
                        <li>
                            <Typography variant="body1">
                                <strong>Post Ads:</strong> Create detailed advertisements for your vehicles, including images and specifications.
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body1">
                                <strong>Edit Posts:</strong> Modify your ads anytime to keep the information up-to-date.
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body1">
                                <strong>Delete Posts:</strong> Remove your ads once your vehicle is sold or if you no longer wish to list it.
                            </Typography>
                        </li>
                    </ul>
                    <Typography variant="body1" paragraph>
                        At Peep.lk, we respect your privacy. We do not use your personal details for any purpose other than to facilitate your ad postings and interactions on our platform.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        If you need any assistance or have any questions, please do not hesitate to contact us at <a href="mailto:send.peep@gmail.com">send.peep@gmail.com</a>. Our team is here to help you with any issues or inquiries you may have.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Thank you for choosing Peep.lk. Happy buying and selling!
                    </Typography>
                </Container>
            </Box>
        </TopNavBar>
    );
};

export default About;
