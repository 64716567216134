import {
    Box, Button, CssBaseline, Stack, Typography, TextField, Select, MenuItem, FormControl, InputLabel, FormHelperText,
    FormControlLabel, Checkbox, CircularProgress,
    Backdrop
} from '@mui/material';
import * as React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import TopNavBar from '../components/TopNavBar';
import { SelectChangeEvent } from '@mui/material/Select';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import SuccessDialog from '../components/SuccessDialog';
import { previousOwnersArray, CAR_BRANDS, LOCATIONS, VEHICLE_TYPES } from '../utility/constants';
import { ImageUploader } from '../components/ImageUploader';
import PostsController from '../controllers/PostsController';
import {
    View,
    useAuthenticator,
} from '@aws-amplify/ui-react';
import Login from '../components/Login';
import { Phone } from '@mui/icons-material';
import UserController from '../controllers/UserController';
import AlertDialog from '../components/AlertDialog';



const PostItem: React.FC = () => {
    const navigate = useNavigate();
    const { savePost, updatePost, status, addPostloader, addPostError, fetchMyPosts, myPosts } = PostsController();
    const { user, signOut, route, authStatus } = UserController()
    const [cityOptions, setCityOptions] = React.useState<string[]>([]);

    // console.log('status----> ', status);
    // console.log('addPostloader----> ', addPostloader);



    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [dialogMessage, setDialogMessage] = React.useState("");

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const [userYor, setUserYor] = React.useState<Dayjs | null>(null);
    const [userYom, setUserYom] = React.useState<Dayjs | null>(null);
    const [yorError, setYorError] = React.useState<boolean>(false);
    const [yomError, setYomError] = React.useState<boolean>(false);
    const [images, setImages] = React.useState<(string | null)[]>([null, null, null, null, null]);
    const [loading, setLoading] = React.useState(false); // Add loading state

    const location = useLocation();
    const { mode } = location.state || {};

    const handleImageUpload = (updatedImages: (string | null)[]) => {
        setImages(updatedImages);

        if (mode?.toUpperCase() == "EDIT") {


            const postData = localStorage.getItem('postDetails')!;

            const post = JSON.parse(postData);
            console.log('EDIT----', post, null, 2)
            setFormData({ ...post, Images: updatedImages });

            setIsChecked(post.urgency);
            setUserYom(dayjs(post.yom));
            setUserYor(dayjs(post.yor));
        }
        else {
            setFormData({ ...formData, Images: updatedImages });
        }

    };

    // const params = useParams<{ ItemId: string }>();
    // console.log(params.ItemId);

    const changeYor = (value: Dayjs | null) => {
        setUserYor(value);
        setFormData({
            ...formData, yor: value ? value?.toString() : ""
        });
        setYorError(false);
    };

    const changeYom = (value: Dayjs | null) => {
        setUserYom(value);
        setFormData({
            ...formData, yom: value ? value?.toString() : ""
        });
        setYomError(false);
    };

    interface ContactInfo {
        userId: string
        name?: string
        email?: string
        phone: string
        address?: string
    }

    interface PostObject {
        id: string;
        postId: string;
        title: string;
        description: string;
        make: string;
        contactInfo: ContactInfo;
        model: string;
        yom: string;
        yor: string;
        mileage: number;
        previosOwners: string;
        Images: (string | null)[];
        Urgency: boolean;
        location: string;
        city: string;
        price: number;
        vehicleType: string;
        postedDate: string;
    }

    const [formData, setFormData] = React.useState<PostObject>({
        id: '',
        postId: '',
        title: '',
        description: '',
        make: '',
        contactInfo: { userId: '', phone: '', email: '' },
        model: '',
        yom: '',
        yor: '',
        mileage: 0,
        previosOwners: '',
        Images: [null, null, null, null, null],
        Urgency: false,
        location: '',
        city: '',
        price: 0,
        vehicleType: '',
        postedDate: dayjs(new Date()).toString()
    });

    const [errors, setErrors] = React.useState({
        title: '',
        description: '',
        make: '',
        phoneNumber: '',
        email: '',
        model: '',
        yom: '',
        yor: '',
        mileage: '',
        previosOwners: '',
        Images: '',
        location: '',
        price: '',
        vehicleType: '',
        city: ''
    });

    const [open, setOpen] = React.useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        // console.log('name---', name);
        // console.log('value---', value);

        if (name === "phoneNumber") {
            setFormData((prevData) => ({
                ...prevData,
                contactInfo: { ...prevData.contactInfo, phone: value }
            }));
        } else if (name === "email") {
            setFormData((prevData) => ({
                ...prevData,
                contactInfo: { ...prevData.contactInfo, email: value }
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value
            }));
        }
    };

    const handleSelectChange = (event: SelectChangeEvent<string>) => {
        const { name, value } = event.target;

        if (name === "location") {
            // Update the formData with the selected location
            setFormData((prevData) => ({
                ...prevData,
                [name as string]: value,
                city: '' // Reset city when location changes
            }));

            // Find the selected location data
            const selectedLocation = LOCATIONS.find(loc => loc.district === value);

            // Update city options based on the selected location
            if (selectedLocation) {
                setCityOptions(selectedLocation.citiesTownsAndJunctions); // Assuming each location has a cities array
            }
        }
        else if (name === "city") {
            setFormData((prevData) => ({
                ...prevData,
                [name as string]: value
            }));
        }
        else {
            setFormData((prevData) => ({
                ...prevData,
                [name as string]: value
            }));
        }
    };

    const validate = () => {
        console.log('VALIDATE------ formData ', JSON.stringify(formData, null, 2))
        let tempErrors = { title: '', description: '', make: '', phoneNumber: '', email: '', model: '', yom: '', yor: '', mileage: '', previosOwners: '', Images: '', location: '', price: '', vehicleType: '', city: '' };
        let isValid = true;

        const phoneRegex = /^(\+?\d{1,3})?[-. (]*\d{1,4}[-. )]*(\d{1,4})[-. ]*(\d{1,4})[-. ]*(\d{1,9})$/;

        const emailRegx = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        const mileageRegx = /^[0-9]/;

        if (formData.title.trim() === '') {
            tempErrors.title = 'Title is required';
            isValid = false;
        }
        if (formData.title.trim().length > 50) {
            tempErrors.title = 'Title is too lengthy';
            isValid = false;
        }
        if (formData.description.trim() === '') {
            tempErrors.description = 'Description is required';
            isValid = false;
        }
        if (formData.contactInfo.phone.trim() === '') {
            tempErrors.phoneNumber = 'Phone number is required';
            isValid = false;
        } else if (!phoneRegex.test(formData.contactInfo.phone.trim())) {
            tempErrors.phoneNumber = 'Phone number must be in the format 0772535750';
            isValid = false;
        }
        if (formData.contactInfo.email && formData.contactInfo.email.trim().length > 1 && !emailRegx.test(formData.contactInfo.email.trim())) {
            tempErrors.email = 'Enter a valid email';
            isValid = false;
        }
        if (formData.make.trim() === '') {
            tempErrors.make = 'Make is required';
            isValid = false;
        }
        if (!userYom) {
            tempErrors.yom = 'Year of Manufacture is required';
            setYomError(true);
            isValid = false;
        }
        if (!userYor) {
            tempErrors.yor = 'Year of Registration is required';
            setYorError(true);
            isValid = false;
        }
        if (formData.mileage === 0 || !mileageRegx.test(formData.mileage.toString().trim())) {
            tempErrors.mileage = 'Mileage is required';
            isValid = false;
        }
        if (formData.Images.every(image => image == null)) {
            tempErrors.Images = 'At least one image is required';
            isValid = false;
        } else {
            tempErrors.Images = '';
        }
        if (formData.location.trim() === '') {
            tempErrors.location = 'District is required';
            isValid = false;
        }
        if (isNaN(formData.price) || formData.price <= 0) {
            tempErrors.price = 'Price is required (Format : 25000)';
            isValid = false;
        }
        if (formData.vehicleType.trim() === '') {
            tempErrors.vehicleType = 'Vehicle type is required';
            isValid = false;
        }
        if (formData.previosOwners === '') {
            tempErrors.previosOwners = 'previosOwners is required';
            isValid = false;
        }

        setErrors(tempErrors);
        return isValid;
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        if (validate()) {
            const cc = images.filter((item) => item != null);
            formData.Images = cc;
            formData.Urgency = isChecked;
            formData.contactInfo.userId = user.userId;

            setLoading(true); // Set loading to true when form submission starts


            //this undefined check added here sometimes url can be changed so the mode get set to undefined
            if (mode !== undefined) {
                if (mode!.toUpperCase() == "NEW") {
                    savePost(formData).then(() => {
                        setOpen(true);
                        setLoading(false);

                    }).catch((err) => {
                        // console.log(err)
                        setDialogMessage(err == "LIMIT_EXCEEDED" ? "Your ad limit exceeded, check out guide for help!" : "Something went wrong, please try again later!");
                        setDialogOpen(true);
                    })
                }
                if (mode!.toUpperCase() == "EDIT") {
                    updatePost(formData).then(() => {
                        setOpen(true);
                        setLoading(false);

                    }).catch((err) => {
                        // console.log("EDIT ERROR")
                        setDialogMessage("Something went wrong, please try again later!");
                        setDialogOpen(true);
                    })
                }
                // Set loading to false when form submission is complete
            } else {
                //if mode undefined adding a new ths happen when url changed to postitem?dasdasd somehting
                savePost(formData).then(() => {
                    setOpen(true);
                    setLoading(false);

                }).catch((err) => {
                    // console.log("ERROR")
                    setDialogMessage("Something went wrong, please try again later!");
                    setDialogOpen(true);
                })

            }

        }
    };
    React.useEffect(() => {
        // setOpen(status);

    })

    const handleClose = () => {
        // console.log('handleClose-----')
        setOpen(false);
        navigate('/mypage');
    };

    const [isChecked, setIsChecked] = React.useState(false);

    const handleCheckboxChange = (event: any) => {
        setIsChecked(event.target.checked);
    };

    // React.useEffect(() => {
    //     setFormData((prevData) => ({
    //         ...prevData,
    //         contactInfo: { ...prevData.contactInfo, id: user.userId }
    //     }));

    // }, [user.userId]);

    //backgroundColor: '#F6F4F3'

    //Edit mode


    const postData = localStorage.getItem('postDetails')!;
    const post = JSON.parse(postData);


    // React.useEffect(() => {
    //     console.log('mode changed/////////////')
    //     setFormData((prevData) => ({
    //         ...prevData,
    //         contactInfo: { ...prevData.contactInfo, id: user.userId }
    //     }));

    // }, [mode]);

    React.useEffect(() => {
        // console.log('Local storage STORED DATA ==== ', JSON.stringify(post, null, 2))
        if (mode?.toUpperCase() == "EDIT") {

            setFormData((prevData) => ({
                ...prevData,
                ...post
            }));
            setIsChecked(post.urgency);
            setUserYom(dayjs(post.yom));
            setUserYor(dayjs(post.yor));

            //--Preload city based on location in edit mode--------
            const selectedLocation = LOCATIONS.find(loc => loc.district === post.location);
            // Update city options based on the selected location
            if (selectedLocation) {
                setCityOptions(selectedLocation.citiesTownsAndJunctions); // Assuming each location has a cities array
            }
            //--Preload city based on location in edit mode--------
        }
        else {
            localStorage.removeItem('postDetails');
            setIsChecked(false);
            setUserYom(null);
            setUserYor(null);
            setFormData({
                id: '',
                postId: '',
                title: '',
                description: '',
                make: '',
                contactInfo: { userId: '', phone: '', email: '' },
                model: '',
                yom: '',
                yor: '',
                mileage: 0,
                previosOwners: '',
                Images: [null, null, null, null, null],
                Urgency: false,
                location: '',
                city: '',
                price: 0,
                vehicleType: '',
                postedDate: dayjs(new Date()).toString()
            })

        }
    }, [mode])

    return (

        <TopNavBar user={user} signOut={signOut} route={route} authStatus={authStatus} >
            <Box sx={{ display: 'flex', minHeight: '100vh', justifyContent: 'center' }}>
                <CssBaseline />
                {route === 'authenticated' ?
                    (
                        <>
                            <Box sx={{ flexGrow: 1, p: 3, maxWidth: '600px', margin: '0 auto', }}>
                                {
                                    //Absolute possitioning loader
                                    addPostloader && <Box
                                        sx={{
                                            position: 'fixed', // Ensure it covers the entire viewport
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: dim background
                                            zIndex: 1300, // Higher than most UI elements
                                        }}
                                    >
                                        <CircularProgress style={{ color: 'red' }} size={100} />
                                    </Box>
                                }
                                <Typography variant="h4" gutterBottom>
                                    Add Vehicle
                                </Typography>
                                {/* <Typography variant="h4" gutterBottom sx={{
                                    fontSize: { xs: 16, sm: 20, md: 24, lg: 28, xl: 32 },
                                    mt: { xs: 2, sm: 3, md: 4, lg: 3, xl: 4 },
                                    mb: { xs: 2, sm: 3, md: 4, lg: 3, xl: 4 }
                                }}>
                                    Vehicle Details
                                </Typography> */}
                                <form onSubmit={handleSubmit}>
                                    <Stack spacing={2}>
                                        <Box>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                                        style={{ color: 'red' }}
                                                        aria-label="dsads"
                                                        checked={isChecked}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                }
                                                label="Urgent Sale"
                                            />
                                        </Box>
                                        <FormControl variant="outlined" fullWidth error={!!errors.vehicleType}>
                                            <InputLabel id="vehicleType-label">Vehicle Type</InputLabel>
                                            <Select
                                                labelId="vehicleType-label"
                                                label="Category"
                                                name="vehicleType"
                                                value={formData.vehicleType}
                                                onChange={handleSelectChange}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {VEHICLE_TYPES.map((item) => (
                                                    <MenuItem key={item} value={item}>{item}</MenuItem>
                                                ))}
                                            </Select>
                                            {errors.vehicleType && <FormHelperText>{errors.vehicleType}</FormHelperText>}
                                        </FormControl>
                                        <FormControl variant="outlined" fullWidth error={!!errors.location}>
                                            <InputLabel id="location-label">District</InputLabel>
                                            <Select
                                                labelId="location-label"
                                                label="Category"
                                                name="location"
                                                value={formData.location}
                                                onChange={handleSelectChange}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {LOCATIONS.map((item) => (
                                                    <MenuItem key={item.district} value={item.district}>{item.district}</MenuItem>
                                                ))}
                                            </Select>
                                            {errors.location && <FormHelperText>{errors.location}</FormHelperText>}
                                        </FormControl>
                                        <FormControl variant="outlined" fullWidth error={!!errors.city}>
                                            <InputLabel id="city-label">City</InputLabel>
                                            <Select
                                                labelId="city-label"
                                                label="Category"
                                                name="city"
                                                value={formData.city}
                                                onChange={handleSelectChange}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {cityOptions.map((city) => (
                                                    <MenuItem key={city} value={city}>{city}</MenuItem>
                                                ))}
                                            </Select>
                                            {errors.city && <FormHelperText>{errors.city}</FormHelperText>}
                                        </FormControl>
                                        <Stack spacing={2}></Stack>
                                        <TextField
                                            label="Ad Heading"
                                            name="title"
                                            variant="outlined"
                                            fullWidth
                                            value={formData.title}
                                            onChange={handleChange}
                                            error={!!errors.title}
                                            helperText={errors.title}
                                        />
                                        <TextField
                                            label="Description"
                                            name="description"
                                            variant="outlined"
                                            fullWidth
                                            multiline
                                            rows={6}
                                            value={formData.description}
                                            onChange={handleChange}
                                            error={!!errors.description}
                                            helperText={errors.description}
                                        />
                                        <TextField
                                            label="Phone Number (0772535750)"
                                            name="phoneNumber"
                                            variant="outlined"
                                            value={formData.contactInfo.phone}
                                            onChange={handleChange}
                                            error={!!errors.phoneNumber}
                                            helperText={errors.phoneNumber}
                                        />
                                        <TextField
                                            label="Email"
                                            name="email"
                                            variant="outlined"
                                            value={formData.contactInfo.email}
                                            onChange={handleChange}
                                            error={!!errors.email}
                                            helperText={errors.email}
                                        />
                                        <FormControl variant="outlined" fullWidth error={!!errors.make}>
                                            <InputLabel id="make-label">Make</InputLabel>
                                            <Select
                                                labelId="make-label"
                                                label="Category"
                                                name="make"
                                                value={formData.make}
                                                onChange={handleSelectChange}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {CAR_BRANDS.map((item) => (
                                                    <MenuItem key={item} value={item}>{item}</MenuItem>
                                                ))}
                                            </Select>
                                            {errors.make && <FormHelperText>{errors.make}</FormHelperText>}
                                        </FormControl>
                                        <TextField
                                            label="Model"
                                            name="model"
                                            variant="outlined"
                                            value={formData.model}
                                            onChange={handleChange}
                                            error={!!errors.model}
                                            helperText={errors.model}
                                        />
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                views={['year']}
                                                label="Year of Manufacture (YOM)"
                                                name="yom"
                                                value={userYom}
                                                onChange={changeYom}
                                                slotProps={{
                                                    textField: {
                                                        error: yomError,
                                                        helperText: "Year of manufacture is required",
                                                    },
                                                }}
                                            />
                                        </LocalizationProvider>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                views={['year', 'month']}
                                                label="Year of Registration (YOR)"
                                                name="yor"
                                                value={userYor}
                                                onChange={changeYor}
                                                slotProps={{
                                                    textField: {
                                                        error: yorError,
                                                        helperText: "Year of registration is required",
                                                    },
                                                }}
                                            />
                                        </LocalizationProvider>
                                        <TextField
                                            label="Mileage (Km)"
                                            name="mileage"
                                            variant="outlined"
                                            value={formData.mileage}
                                            onChange={handleChange}
                                            error={!!errors.mileage}
                                            helperText={errors.mileage}
                                        />
                                        <FormControl variant="outlined" fullWidth error={!!errors.previosOwners}>
                                            <InputLabel id="previosOwners-label">Previous Owners</InputLabel>
                                            <Select
                                                labelId="previosOwners-label"
                                                label="Previous Owners"
                                                name="previosOwners"
                                                value={formData.previosOwners}
                                                onChange={handleSelectChange}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {previousOwnersArray.map((item) => (
                                                    <MenuItem key={item} value={item}>{item}</MenuItem>
                                                ))}
                                            </Select>
                                            {errors.previosOwners && <FormHelperText>{errors.previosOwners}</FormHelperText>}
                                        </FormControl>
                                        <TextField
                                            label="Price(Rs)"
                                            name="price"
                                            variant="outlined"
                                            value={formData.price}
                                            onChange={handleChange}
                                            error={!!errors.price}
                                            helperText={errors.price}
                                        />
                                        { /* this undefined check added here sometimes url can be changed so the mode get set to undefined */}
                                        <ImageUploader onImageUpload={handleImageUpload} errorMgs={errors.Images} images={images} mode={mode == undefined ? "" : mode} editImageUrls={mode !== undefined && mode.toUpperCase() == "EDIT" ? post.imagePaths : images} />
                                        <Button sx={{ height: '60px' }} variant="contained" color="primary" type="submit" disabled={addPostloader} >
                                            <h3>Add Vehicle</h3>
                                        </Button>

                                    </Stack>
                                </form>
                            </Box>
                            <SuccessDialog open={open} onClose={handleClose} />
                            <AlertDialog
                                open={dialogOpen}
                                onClose={handleDialogClose}
                                message={dialogMessage}
                            />
                        </>
                    ) :
                    <View padding="xxl" marginTop={100}>
                        <Login />
                    </View>


                }
            </Box >
        </TopNavBar>
    );
};

export default PostItem;
