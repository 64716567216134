import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import { Card, CardContent, CircularProgress, Container, Grid, Icon, Link, Pagination, Stack, SvgIcon } from '@mui/material';
import Item from './components/Item';
import TopNavBar from './components/TopNavBar';
import PostsController from './controllers/PostsController';
import SearchBar from './components/SearchBar';
import SortButton from './components/SortButton';
import { LOCATIONS, VEHICLE_TYPES, CAR_BRANDS } from './utility/constants';
import { useAuthenticator } from '@aws-amplify/ui-react';
import UserController from './controllers/UserController';
import gifImage from './assets/homeAd.gif';
import SkeletonLoader from './components/SkeltonLoader';



const Home: React.FC = () => {

  const { posts, loader, loadMorePosts, searchFilters, setUserFilters } = PostsController();
  const { user, signOut, route, authStatus } = UserController()

  React.useEffect(() => {
    // console.log('authStatus-------', authStatus)
  })
  interface GetAllPostsParams {
    vt?: string;
    l?: string;
    m?: string;
    s?: string;
    sort?: string;
    page?: number;
  }
  const [searchParams, setSearchParams] = React.useState<GetAllPostsParams>({
    vt: '',
    l: '',
    m: '',
    s: '',
    sort: '',
    page: searchFilters.page,
  });

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    // console.log("Pagination click--- page : ", value)
    setSearchParams((prevParams) => ({
      ...prevParams,
      page: value,
    }));
    loadMorePosts(value)
  };

  return (

    <TopNavBar user={user} signOut={signOut} route={route} authStatus={authStatus}>
      <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', paddingBottom: 5 }}>
        <CssBaseline />
        <Grid
          sx={{ width: '100%' }}
          direction="column"
          justifyContent="center">
          <Grid item md={8} sm={10} xs={12} container sx={{ display: 'flex', width: '100%' }} >
            <SearchBar
              vehicleTypes={VEHICLE_TYPES}
              locations={LOCATIONS}
              makes={CAR_BRANDS}
              searchFilters={searchFilters}
              setUserFilters={setUserFilters}
            />
            <Grid item md={12} sm={10} xs={12} sx={{ marginTop: 2 }} >
              <SortButton searchFilters={searchFilters} setUserFilters={setUserFilters}
              />
            </Grid>
            <Grid item xs={12} md={8} sm={10} minHeight={500} >

              {
                // loader ? <Box sx={{ display: 'flex', flex: 1, height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                //   <CircularProgress style={{ color: '#FF0000' }} size={50} />
                // </Box>
                loader ? <Box sx={{ marginTop: 2, height: '90%', justifyContent: 'center', alignItems: 'center' }}><SkeletonLoader /></Box>
                  :
                  posts.data.length === 0 ?
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 2 }}>
                      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: 2 }}>
                        <Typography variant="h6" color="textSecondary" gutterBottom>
                          No vehicles found
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          Try adjusting your search criteria or check back later.
                        </Typography>
                      </Box>

                    </Box>
                    : (
                      posts.data.map((result) => (

                        result && <Item key={result.id} ItemData={{ ...result }}></Item>
                      ))
                    )

              }

              {<Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                <Pagination
                  count={posts.last_page}
                  page={searchParams.page}
                  onChange={handlePageChange}
                  color="primary"
                  size="large"
                  sx={{
                    button: { fontSize: '1.25rem', margin: '0 10px' },
                    '& .Mui-selected': { fontSize: '1.5rem', fontWeight: 'bold' },
                    marginTop: '20px'
                  }}
                />
              </Box>
              }

            </Grid>
            {/* <div style={{ width: 10 }}></div> */}
            <Grid item md={4} sm={10} xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Grid item md={11.5} sm={12} xs={12} >
                <div style={{ marginTop: 16 }}>
                  <img src={gifImage} alt="Descriptive text for the GIF" style={{ width: '100%', height: 'auto' }} />
                </div>

              </Grid>

            </Grid>
          </Grid>
        </Grid >
      </Box >
    </TopNavBar>

  );
}



const App: React.FC = () => {
  return (
    <Home />
  );

};
export default App;

