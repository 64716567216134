import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, IconButton, Button } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

interface SuccessDialogProps {
    open: boolean;
    onClose: () => void;
}

const SuccessDialog: React.FC<SuccessDialogProps> = ({ open, onClose }) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            sx={{
                '& .MuiDialog-paper': {
                    width: '90%', // Default for mobile
                    maxWidth: { xs: '90%', sm: '80%', md: '60%', lg: '40%' }, // Adjust for larger screens
                }
            }}
        >
            <DialogTitle fontSize={40}>
                <IconButton aria-label="success" sx={{ color: 'green' }}>
                    <CheckCircleOutlineIcon />
                </IconButton>
                Success
            </DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ color: 'green' }} fontSize={18}>
                    The ad has been successfully posted! all the best with your selling!
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} sx={{ color: 'green' }}>
                    OK
                </Button>
            </DialogActions>
        </Dialog >
    );
};

export default SuccessDialog;
