import { Typography, Button, IconButton } from '@mui/material';
import { PhotoCamera, Delete } from '@mui/icons-material';
import * as React from 'react';

interface ImageUploaderProps {
    onImageUpload: (imagesArray: (string | null)[]) => void;
    errorMgs: string;
    images: (string | null)[];
    mode: string
    editImageUrls: (string)[]
}
export const ImageUploader: React.FC<ImageUploaderProps> = ({ onImageUpload, errorMgs, images, mode, editImageUrls }) => {

    const [existingb64Img, setExistingb64Img] = React.useState<(string | null)[]>([null, null, null, null, null]);
    const existingImages: (string | null)[] = [null, null, null, null, null];
    React.useEffect(() => {
        if (mode.toUpperCase() == "EDIT") {
            //Converting web urls to base64 string
            const fetchImageAsBase64 = async (url: string): Promise<string> => {
                const response = await fetch(url, {
                    method: 'GET',
                    mode: 'cors',           // Ensures CORS is handled
                    cache: 'no-cache',      // Prevents caching issues
                    headers: {
                        'Content-Type': 'application/octet-stream'
                    }
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const blob = await response.blob();
                return new Promise<string>((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        if (reader.result) {
                            // Extract base64 part from data URL
                            const base64String = (reader.result as string).split(',')[1];
                            resolve(base64String);
                        } else {
                            reject('Failed to convert image to base64');
                        }
                    };
                    reader.readAsDataURL(blob);
                });
            };

            const convertUrlsToBase64 = async (urls: string[]): Promise<string[]> => {
                const base64Promises = urls.map(url => fetchImageAsBase64(url));
                return Promise.all(base64Promises);
            };

            convertUrlsToBase64(editImageUrls).then(base64Array => {
                // Array of base64 strings
                setExistingb64Img(base64Array);
                //-----------------
                // console.log('base64Array==>', base64Array)

                base64Array.forEach((base64, index) => {
                    if (index < images.length) {


                        existingImages[index] = base64;
                    }

                    setExistingb64Img(existingImages);
                });

                // Call the parent callback with the updated images
                onImageUpload(existingImages);
                // console.log('NEW images array', existingImages);

                ///---------------
            }).catch(error => {
                console.error('Error converting images to base64:', error);
            });

            // Update the copied array with new base64 strings at the specified indices

        }

    }, [])


    //-------------------------------------------------------


    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                let base64String = reader.result as string;

                // Remove the data URL prefix to get the pure base64 string
                if (base64String.startsWith("data:")) {
                    base64String = base64String.split(",")[1];
                }
                const updatedImages = mode.toUpperCase() === "EDIT" ? existingb64Img : images;
                updatedImages[index] = base64String;
                onImageUpload(updatedImages);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleImageDelete = (index: number) => {
        const updatedImages = mode.toUpperCase() === "EDIT" ? existingb64Img : [...images];
        updatedImages[index] = null;
        onImageUpload(updatedImages);
    };


    // console.log('===================', existingb64Img);

    const displayimg = mode.toUpperCase() === "EDIT" ? existingb64Img : images;

    return (

        <div>
            {errorMgs && <Typography style={{ color: '#d43635', fontSize: 12 }}>{errorMgs}</Typography>}
            {
                displayimg.map((image, index) => (
                    <div key={index} style={{ marginBottom: 16 }}>
                        <input
                            accept="image/*"
                            style={{ display: 'none' }}
                            id={`image-upload-${index}`}
                            type="file"
                            onChange={(event) => handleImageUpload(event, index)}
                        />
                        <label htmlFor={`image-upload-${index}`}>
                            <Button variant="contained" component="span" startIcon={<PhotoCamera />}>
                                {image ? 'Change Image' : 'Upload Image'}
                            </Button>
                        </label>
                        {image && (
                            <div style={{ marginTop: 16 }}>
                                <img src={"data:image/jpeg;base64," + image} alt="Preview" style={{ maxWidth: '100%', maxHeight: 200 }} />
                                <IconButton onClick={() => handleImageDelete(index)} aria-label="delete" color="secondary">
                                    <Delete />
                                </IconButton>
                            </div>
                        )}
                    </div>
                ))
            }
        </div >
    );
};
