import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Card, CardContent, CardMedia, CardActionArea, Box } from '@mui/material';

const SkeletonItem: React.FC = () => {
    return (
        <Card style={{ display: 'flex', margin: '16px 0', boxShadow: 'none', backgroundColor: '#fbfaf9' }} sx={{
            height: {
                xs: 110,
                sm: 150,
                md: 150,
            },
        }}>
            <CardActionArea style={{ display: 'flex' }}>
                <CardMedia
                    component="div"
                    style={{ width: '40%', height: '100%' }}
                    sx={{ objectFit: 'contain' }}
                >
                    <Skeleton height="100%" />
                </CardMedia>
                <CardContent style={{ width: '67%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    {/* <div>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: 'row',
                            width: '100%',
                        }}>
                            <div><Skeleton width="40%" height={20} /></div>
                            <div><Skeleton width="40%" height={20} /></div>
                        </div>

                    </div> */}
                    <Skeleton width="80%" height={20} />
                    <div style={{

                        justifyContent: 'space-between',
                    }}>
                        <Skeleton width="80%" height={20} />
                        {/* <Skeleton width={50} height={20} /> */}
                    </div>
                </CardContent>
            </CardActionArea>
        </Card >
    );
};

export default SkeletonItem;
