import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axiosInstance";
import { ApiResponse, PostData } from "../../constants/constants";

interface GetAllPostsParams {
  vt?: string;
  l?: string;
  m?: string;
  s?: string;
  sort?: string;
  page?: number;
}

function isAxiosError(error: any): error is { response: { data: any } } {
  return error.isAxiosError && error.response;
}

export const getAllPosts = createAsyncThunk<ApiResponse, GetAllPostsParams>(
  "GET_POSTS/getAllPosts",
  async (params, { rejectWithValue }) => {
    try {
      // Filter out params with undefined or null values
      const filteredParams = Object.fromEntries(
        Object.entries(params).filter(([key, value]) => value != null)
      );

      const { data } = await axiosInstance.get(`/api/Post/GetAllPosts`, {
        headers: {
          "Content-Type": "application/json",
        },
        params: filteredParams,
      });
      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue("ERROR:GET_POSTS/getAllPosts");
    }
  }
);

export const loadMore = createAsyncThunk<ApiResponse, GetAllPostsParams>(
  "GET_POSTS/loadMore",
  async (params, { rejectWithValue }) => {
    try {
      // Filter out params with undefined or null values
      const filteredParams = Object.fromEntries(
        Object.entries(params).filter(([key, value]) => value != null)
      );

      const { data } = await axiosInstance.get(`/api/Post/GetAllPosts`, {
        headers: {
          "Content-Type": "application/json",
        },
        params: filteredParams,
      });
      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue("ERROR:GET_POSTS/loadMore");
    }
  }
);

export const addPost = createAsyncThunk<PostData, any>(
  "ADD_POST/addPost",
  async (postData, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post(`/api/Post/Add`, postData, {
        headers: {
          "Content-Type": "application/json",
          // Add this line if you control the server
        },
      });
      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue("ERROR:ADD_POST/addPost");
    }
  }
);

export const updateUserPost = createAsyncThunk<PostData, PostData>(
  "ADD_POST/updateUserPost",
  async (postData, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.patch(`/api/Post/Update`, postData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue("ERROR:ADD_POST/updateUserPost");
    }
  }
);

export const deleteUserPost = createAsyncThunk<boolean, string>(
  "ADD_POST/deleteUserPost",
  async (postId, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.delete(
        `/api/Post/Delete?postId=${postId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue("ERROR:ADD_POST/deleteUserPost");
    }
  }
);

export const fetchUserSpecificPosts = createAsyncThunk<ApiResponse, string>(
  "GET_POSTS/fetchUserSpecificPosts",
  async (userId, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `/api/Post/userPosts?userId=${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            // Add this line if you control the server
          },
        }
      );
      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue("ERROR:GET_POSTS/fetchUserSpecificPosts");
    }
  }
);

export const getPostById = createAsyncThunk<ApiResponse, string>(
  "GET_POSTS/getPostById",
  async (postId, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`/api/Post/${postId}`, {
        headers: {
          "Content-Type": "application/json",
          // Add this line if you control the server
        },
      });
      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue("ERROR:GET_POSTS/getPostById");
    }
  }
);

export const warmUpLambda = createAsyncThunk<ApiResponse>(
  "GET_POSTS/warmUpLambda",
  async (postId, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`/api/post/pin/`, {
        headers: {
          "Content-Type": "application/json",
          // Add this line if you control the server
        },
      });
      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue("ERROR:GET_POSTS/getPostById");
    }
  }
);
