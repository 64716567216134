import React from 'react';
import { Box, Typography, Button, CssBaseline } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useNavigate } from 'react-router-dom';

const ErrorPage: React.FC = () => {
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1); // Navigate to the previous page
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',
                backgroundColor: '#f5f5f5',
                textAlign: 'center',
                p: 3,
            }}
        >
            <CssBaseline />
            <ErrorOutlineIcon sx={{ fontSize: 100, color: 'red', mb: 2 }} />
            <Typography variant="h3" gutterBottom>
                Oops! Something went wrong.
            </Typography>
            <Typography variant="h6" color="textSecondary" paragraph>
                We are sorry, but an unexpected error has occurred. Please try again later.
            </Typography>
            <Button
                variant="contained"
                color="primary"
                onClick={handleGoBack}
                sx={{ mt: 2 }}
            >
                Go Back
            </Button>
        </Box>
    );
};

export default ErrorPage;
