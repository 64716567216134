import axios from "axios";
import { fetchAuthSession } from "aws-amplify/auth";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  async (config) => {
    try {
      const session = await fetchAuthSession();
      const idToken = session.tokens!.idToken; // Retrieve the ID token

      if (idToken) {
        config.headers.Authorization = `Bearer ${idToken}`;
      }
    } catch (error) {
      console.error("Error fetching ID token:", error);
    }

    return config;
  },
  (error) => {
    // Handle request errors here
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // Handle response errors here
    console.error("Axios error:", error);
    return Promise.reject(error);
  }
);

export default axiosInstance;
