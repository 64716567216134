import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Paper, IconButton, TextField, InputAdornment } from '@mui/material';
import TopNavBar from '../components/TopNavBar';
import UserController from '../controllers/UserController';
import bglanding from '../images/bglanding.jpeg'
import SearchIcon from '@mui/icons-material/Search';
import bike from '../assets/landingIcons/bikeNobg.png'
import car from '../assets/landingIcons/carNobg.png'
import van from '../assets/landingIcons/vanNobg.png'
import bus from '../assets/landingIcons/busNobg.png'
import lorry from '../assets/landingIcons/lorryNobg.png'
import bicycle from '../assets/landingIcons/bicycleNobg.png'
import mopad from '../assets/landingIcons/mopadNobg.png'
import scooter from '../assets/landingIcons/scooterNobg.png'
import trishaw from '../assets/landingIcons/trishawNobg.png'
import conv from '../assets/landingIcons/conv2Nobg.png'
import minivan from '../assets/landingIcons/minivanNobg.png'
import truck from '../assets/landingIcons/truckNobg.png'
import jeep from '../assets/landingIcons/jeepNobg.png'
import cab from '../assets/landingIcons/cabNoBg.png'
import ship from '../assets/landingIcons/shipNobg.png'

import { VEHICLE_TYPES } from '../utility/constants';
import WarmupController from '../controllers/WarmupController'

// Icon mapping
const ICONS_MAP: { [key: string]: React.ReactNode } = {
    "Car": car,
    "Van": van,
    "Jeep": jeep,
    "Cab": cab,
    "Bus": bus,
    "Lorry": lorry,
    "Bicycle": bicycle,
    "Motorcycle": bike,
    "Mopad": mopad,
    "Scooter": scooter,
    "Three-wheeler": trishaw,
    "Convertible": conv,
    "Minivan": minivan,
    "Truck": truck,
    "Ship/Boat": ship,
};

const LandingPage: React.FC = () => {
    const { user, signOut, route, authStatus } = UserController()
    WarmupController();
    const [searchText, setSearchTerm] = useState("");
    const navigate = useNavigate();

    const searchFieldRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (searchFieldRef.current) {
            searchFieldRef.current.focus();
        }
    }, []);

    const handleSearch = () => {
        navigate('/ads');
    };

    useEffect(() => {
        //Call warm up lambda
    }, [])

    return (

        <TopNavBar user={user} signOut={signOut} route={route} authStatus={authStatus} >
            <div>
                {/* Background Image Section */}
                <div
                    style={{
                        width: '100%',
                        height: '360px',
                        backgroundImage: `url(${bglanding})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        position: 'relative',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column'
                    }}
                >
                    <div
                        style={{
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            padding: '20px',
                            borderRadius: '8px',
                            color: 'white',
                            textAlign: 'center',
                        }}
                    >
                        <h1>Welcome to Peep.lk</h1>
                        <p>Your go-to platform for buying and selling vehicles.</p>

                    </div>
                    <TextField
                        variant="outlined"
                        placeholder="Search"
                        value={searchText}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        inputRef={searchFieldRef}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={handleSearch}
                                        sx={{

                                            p: 1,
                                            backgroundColor: 'white',
                                            '&:hover': {
                                                backgroundColor: 'red',
                                            },
                                            '&:active': {
                                                backgroundColor: 'lightred',
                                            },
                                            '& input': {
                                                color: 'white', // Text color
                                                fontWeight: 'bold', // Font weight
                                            },
                                            '& input::placeholder': {
                                                color: 'white', // Placeholder text color
                                            },
                                        }}
                                    >
                                        <SearchIcon sx={{ fontSize: 30 }} />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}

                        sx={{
                            width: '70%',
                            marginTop: 3,
                            backgroundColor: '#000000',
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'white', // Border color when not focused
                                    borderWidth: '1px', // Border width
                                },
                                '&:hover fieldset': {
                                    borderColor: 'darkred', // Border color on hover
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: 'darkred', // Border color when focused
                                },
                                '& input': {
                                    color: 'white', // Text color
                                    fontWeight: 'bold', // Font weight
                                    fontSize: '20px',


                                },
                                '& input::placeholder': {
                                    color: 'white', // Placeholder text color
                                },
                            },
                            '& .MuiInputAdornment-root': {
                                color: 'white', // Color of the adornment (icon) when inside the input field
                            },
                        }}
                    />
                </div>

                {/* Icons Section */}
                <Box sx={{ marginTop: 5 }}><h3>Discover our categories</h3></Box>
                <div
                    style={{

                        width: '100%',
                        padding: '0px 20px',
                        paddingBottom: 25,
                        backgroundColor: '#f6f4f3', // Background color for the section
                        display: 'grid',
                        gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))', // Auto-adjusting columns
                        gap: '20px', // Space between items
                    }}
                >

                    {VEHICLE_TYPES.map((item) => (
                        <ClickableIcon key={item} icon={ICONS_MAP[item]!.toString()} label={item} onClick={() => navigate('/ads')} />

                    ))}


                </div>
            </div>
        </TopNavBar>

    );
};

interface ClickableIconProps {
    icon: string;
    label: string;
    onClick: () => void
}

const ClickableIcon: React.FC<ClickableIconProps> = ({ icon, label, onClick }) => {
    const handleClick = () => {
        onClick();
    };

    return (
        <Paper sx={{ borderRadius: 1 }} elevation={0}>
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    borderRadius: 1,
                    bgcolor: '#f6f4f3',

                }}
            >
                <div
                    onClick={handleClick}
                    style={{
                        flexDirection: 'column',
                        cursor: 'pointer',
                        display: 'flex', // Use flexbox for alignment
                        justifyContent: 'flex-start', // Center horizontally
                        alignItems: 'center', // Center vertically

                    }}

                >
                    <Box
                        sx={{
                            color: '#555', // Color of the icons
                            transition: 'color 0.3s ease',
                            backgroundColor: '#ffff',
                            width: '90px',
                            height: '90px',
                            borderRadius: '50%',
                            display: 'flex', // Use flexbox for alignment
                            justifyContent: 'center', // Center horizontally
                            alignItems: 'center',
                            '&:hover': {
                                bgcolor: 'white',//#DAF7A6
                            },

                        }}

                    >
                        <img
                            src={icon}
                            alt="Bike"
                            style={{
                                width: '60px',
                                height: '60px',
                            }}
                        />
                    </Box>
                    <p style={{ marginTop: '10px', fontWeight: '100', color: '#333' }}>{label}</p>
                </div>
            </Box>
        </Paper >
    );
};

export default LandingPage;


