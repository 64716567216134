import {
    Box, Button, Typography, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    Alert
} from '@mui/material';
import * as React from 'react';
import TopNavBar from '../components/TopNavBar';
import PostsController from '../controllers/PostsController';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useAuthenticator } from '@aws-amplify/ui-react';
import Login from '../components/Login';
import { PostData } from '../constants/constants';
import UserController from '../controllers/UserController';
import AlertDialog from '../components/AlertDialog';
import Skeleton from 'react-loading-skeleton';

interface ContactInfo {
    Id?: number;
    Name?: string;
    Email?: string;
    Phone: string;
    Address?: string;
}

interface PostObject {
    id: string;
    title: string;
    description: string;
    make: string;
    ContactInfo: ContactInfo;
    phoneNumber: string;
    email: string;
    model: string;
    yom: string;
    yor: string;
    mileage: string;
    previosOwners: string;
    Images: (string | null)[];
    Urgency: boolean;
    location: string;
    price: string;
    vehicleType: string;
    postedDate: string;
}

const MyPage: React.FC = () => {
    const { user, signOut, route, authStatus } = UserController()
    const { searchFilters, fetchMyPosts, myPosts, myPostsLoader, deletePost, deleteStatus } = PostsController();
    // const [loading, setLoading] = React.useState(true);
    const [deleteLoading, setDeleteLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [postToDelete, setPostToDelete] = React.useState<string | null>(null);

    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [dialogMessage, setDialogMessage] = React.useState("");

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const navigate = useNavigate();
    // console.log('USER--------', JSON.stringify(user ? user : ''))
    React.useEffect(() => {
        localStorage.removeItem('postDetails')
        // const fetchPosts = async () => {
        //     setLoading(false);
        // };
        // fetchPosts();


        if (authStatus === 'authenticated' && user && deleteStatus) {
            fetchMyPosts(user.userId).then().catch(() => {
                setDialogMessage("Something went wrong, please try again later!");
                setDialogOpen(true);
            })
        }

    }, [user?.userId, deleteStatus]);

    React.useEffect(() => {
        if (user) {

            fetchMyPosts(user.userId).then().catch(() => {
                setDialogMessage("Something went wrong, please try again later!");
                setDialogOpen(true);
            })

        }
    }, [user]);

    const handleDelete = async (postId: string) => {
        setPostToDelete(postId);
        setOpen(true);
    };

    const confirmDelete = async () => {
        setDeleteLoading(true);
        if (postToDelete !== null) {
            deletePost(postToDelete).then(() => {
                setDialogMessage("Post deteled successfuly!");
                setDialogOpen(true);
            }).catch(() => {
                setDialogMessage("Deletion not working at the moment!,please try later!");
                setDialogOpen(true);
            })
            // await deletePost(postToDelete);
            // setPosts(posts.filter(post => post.id !== postToDelete));
        }
        setDeleteLoading(false);
        setOpen(false);
    };

    const handleAddPost = () => {
        navigate('/add-post'); // Navigate to add post page
    };

    const handleClick = async (post: PostData) => {
        // Store the post data in localStorage
        localStorage.setItem('postDetails', JSON.stringify(post));
        // Navigate to the details page
        navigate('/postitem', { state: { mode: 'edit' } });
    };



    return (

        <TopNavBar user={user} signOut={signOut} route={route} authStatus={authStatus} >
            <Box sx={{ display: 'flex', minHeight: '100vh' }}>
                <Box sx={{ flexGrow: 1, p: 3, maxWidth: '800px', margin: '0 auto' }}>
                    <Typography variant="h4" gutterBottom>My Posts</Typography>
                    {authStatus === 'authenticated' && user && <>
                        {myPostsLoader ? (
                            <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'space-evenly', mt: 5, alignItems: 'center' }}>
                                <div style={{ width: '100%', paddingBottom: '10px' }}> <Skeleton width="100%" height={160} baseColor='#fbfaf9' /></div>
                                <div style={{ width: '100%', paddingBottom: '10px' }}> <Skeleton width="100%" height={160} baseColor='#fbfaf9' /></div>
                                <div style={{ width: '100%', paddingBottom: '10px' }}> <Skeleton width="100%" height={160} baseColor='#fbfaf9' /></div>
                            </Box>
                        ) : (
                            myPosts.data.length === 0 ? (
                                <Typography variant="body1" sx={{ mt: 3 }}>No posts found.</Typography>
                            ) : (
                                <Box sx={{ mt: 3 }}>
                                    {myPosts.data.length >= 3 && <Alert severity="warning" sx={{ marginBottom: 2 }}>You have reached the maximum number (3) of ads allowed for you.</Alert>}
                                    {myPosts.data.map((post) => (
                                        <Box key={post.id} sx={{ mb: 3, p: 2, border: '1px solid #ccc', borderRadius: '8px', display: 'flex', flexDirection: 'column' }}>
                                            <Box sx={{ flexGrow: 1 }}>
                                                {/* Container for title and date */}
                                                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                    <Typography variant="h6">{post.title}</Typography>
                                                    <Typography variant="body2" color="textSecondary">
                                                        Posted date: {dayjs(post.postedDate).format('DD/MM/YYYY')}
                                                    </Typography>
                                                </Box>
                                                {/* <Typography variant="body1">{post.description}</Typography> */}
                                            </Box>
                                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                <Box sx={{ flexShrink: 0, mr: 2 }}>
                                                    {post.imagePaths[0] && (
                                                        <img
                                                            src={post.imagePaths[0]}
                                                            alt={post.title}
                                                            style={{ width: '150px', height: 'auto', borderRadius: '8px' }}
                                                        />
                                                    )}
                                                </Box>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                                    <Button variant="contained" color="secondary" onClick={() => handleDelete(post.postId)} sx={{ mb: 1 }}>
                                                        Delete
                                                    </Button>
                                                    {/* <Button variant="contained" color="secondary">
                                                    Delete
                                                </Button> */}
                                                    <Button variant="contained" color="secondary" onClick={() => handleClick(post)}>
                                                        Edit
                                                    </Button>
                                                </Box>

                                            </Box>

                                        </Box>
                                    ))}
                                </Box>
                            )
                        )}
                    </>
                    }
                </Box>
                <Dialog open={open} onClose={() => setOpen(false)}>
                    <DialogTitle>Confirm Delete</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Are you sure you want to delete this post?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpen(false)} color="primary">Cancel</Button>
                        <Button onClick={confirmDelete} color="secondary" disabled={deleteLoading}>
                            {deleteLoading ? <CircularProgress size={24} /> : 'Delete'}
                        </Button>
                    </DialogActions>
                </Dialog>
                <AlertDialog
                    open={dialogOpen}
                    onClose={handleDialogClose}
                    message={dialogMessage}
                />
            </Box>
        </TopNavBar >
    );
};

export default MyPage;
