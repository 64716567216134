import React from 'react';
import { Box, Typography, Container, Link } from '@mui/material';
import TopNavBar from '../../components/TopNavBar';
import UserController from '../../controllers/UserController';

const Terms: React.FC = () => {
    const { user, signOut, route, authStatus } = UserController()
    const baseUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://peep.lk';
    const privacy = `${baseUrl}/privacy`
    return (
        <TopNavBar user={user} signOut={signOut} route={route} authStatus={authStatus} >
            <Box sx={{ backgroundColor: '#f4f4f4', minHeight: '100vh', padding: '2rem 0' }}>
                <Container maxWidth="md">
                    <Typography variant="h4" gutterBottom>
                        Terms and Conditions
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Welcome to Peep.lk. By using our website, you agree to comply with and be bound by the following terms and conditions. Please review them carefully. If you do not agree to these terms and conditions, you should not use this website.
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        1. Acceptance of Agreement
                    </Typography>
                    <Typography variant="body1" paragraph>
                        You agree to the terms and conditions outlined in this Terms and Conditions Agreement with respect to our site (Peep.lk). This Agreement constitutes the entire and only agreement between us and you, and supersedes all prior or contemporaneous agreements, representations, warranties, and understandings with respect to the site, the content, products, or services provided by or through the site.
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        2. Modification of Terms
                    </Typography>
                    <Typography variant="body1" paragraph>
                        We reserve the right to change these terms and conditions at any time. Updated versions of the terms and conditions will appear on this website and are effective immediately. You are responsible for regularly reviewing the terms and conditions. Continued use of the website after any such changes constitutes your consent to such changes.
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        3. Posting Ads
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Users can post ads for vehicles they wish to sell. Each user is allowed to have up to three active posts at any time. The content of the ads must be accurate and not misleading. Peep.lk reserves the right to remove any ads that violate our policies or terms and conditions.
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        4. User Responsibilities
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Users are responsible for maintaining the confidentiality of their account information, including their password, and for all activities that occur under their account. Users agree to notify Peep.lk immediately of any unauthorized use of their account or any other breach of security.
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        5. Privacy
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Peep.lk respects your privacy. We do not use your personal details for any purpose other than to facilitate your ad postings and interactions on our platform. For more details, please refer to our <Link href={privacy} target="_blank">Privacy Policy</Link>.
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        6. Limitation of Liability
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Peep.lk will not be liable for any damages of any kind arising from the use of this site, including, but not limited to direct, indirect, incidental, punitive, and consequential damages.
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        7. Governing Law
                    </Typography>
                    <Typography variant="body1" paragraph>
                        These terms and conditions are governed by and construed in accordance with the laws of Sri Lanka. You agree to submit to the exclusive jurisdiction of the courts located in Sri Lanka for any dispute arising out of or relating to your use of this website.
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        8. Contact Us
                    </Typography>
                    <Typography variant="body1" paragraph>
                        If you have any questions about these Terms and Conditions, please contact us at <a href="mailto:send.peep@gmail.com">send.peep@gmail.com</a>.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Thank you for using Peep.lk.
                    </Typography>
                </Container>
            </Box>
        </TopNavBar>
    );
};

export default Terms;
