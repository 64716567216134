import { useNavigate } from 'react-router-dom';
import { Card, CardContent, Typography, CardMedia, CardActionArea, Tooltip, Box } from '@mui/material';
import * as React from 'react';
import dayjs from 'dayjs';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Urgent } from './Urgent';
import { formatNumberWithThousandSeparators } from '../utility/utils';

interface ItemData {
    id: number,
    title: string,
    description: string,
    imagePaths: [string],
    location: string,
    city: string,
    urgency: boolean,
    price: string,
    postedDate: string
    postId: string,
    mileage: string
}

interface Props {
    ItemData: ItemData
}

const Item: React.FC<Props> = ({ ItemData }) => {

    const { id, title, description, imagePaths, location, urgency, price, postedDate, postId, mileage, city } = ItemData;
    const navigate = useNavigate(); // Hook to programmatically navigate
    const itemLocation = location + (city == "" ? "" : "," + city)

    const handleClick = () => {
        // Store the post data in localStorage
        localStorage.setItem('postDetails', JSON.stringify(ItemData));

        // Navigate to the details page
        navigate(`/details/${postId}`);
    };

    return (
        <Card style={{ display: 'flex', margin: '16px 0', boxShadow: "none", backgroundColor: '#fbfaf9' }} sx={{
            height: {
                xs: 120, // small font size for extra-small screens
                sm: 150, // regular font size for small screens
                md: 150,
            }
        }}>
            <CardActionArea style={{ display: 'flex' }} onClick={handleClick} sx={{
                objectFit: "contain",
                flexDirection: 'row',
                justifyContent: 'flex-start',
            }}>
                <CardMedia

                    component="img"
                    style={{ backgroundColor: '#ffffff' }}
                    image={imagePaths[0]}
                    alt={title}
                    sx={{
                        objectFit: "contain",
                        width: {
                            xs: 120, // small font size for extra-small screens
                            sm: 150, // regular font size for small screens
                            md: 255,
                        },
                        height: {
                            xs: 120, // small font size for extra-small screens
                            sm: 150, // regular font size for small screens
                            md: 150,
                        }
                    }}
                />
                <CardContent style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} sx={{
                    objectFit: "contain",
                    width: {
                        xs: 500, // small font size for extra-small screens
                        sm: 500, // regular font size for small screens
                        md: 600,
                    },
                    height: {
                        xs: 120, // small font size for extra-small screens
                        sm: 150, // regular font size for small screens
                        md: 175,
                    }
                }}>
                    <div>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}>
                            <Typography variant="body2" color="textSecondary">{itemLocation}</Typography>
                            <Typography variant="body2" color="textSecondary">{dayjs(postedDate).format("DD/MM/YYYY")}</Typography>
                        </div>
                        <Typography variant="h6" sx={{
                            textAlign: 'left',
                            fontSize: {
                                xs: '0.875rem', // small font size for extra-small screens
                                sm: '1rem', // regular font size for small screens
                                md: '1.25rem', // medium font size for medium screens and above
                            },
                        }}>{title}</Typography>
                        <Typography variant="h1" sx={{
                            textAlign: 'left',
                            fontSize: {
                                xs: '0.7rem', // small font size for extra-small screens
                                sm: '0.8rem', // regular font size for small screens
                                md: '1rem', // medium font size for medium screens and above
                            },
                        }}>{mileage + " (Km)"}</Typography>
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}>
                        <Typography variant="body1" fontWeight={'bold'} sx={{
                            color: 'bold', alignSelf: 'flex-start', fontSize: {
                                xs: '0.875rem', // small font size for extra-small screens
                                sm: '1rem', // regular font size for small screens
                                md: '1.25rem', // medium font size for medium screens and above
                            }
                        }}>{"Rs. " + formatNumberWithThousandSeparators(price)}</Typography>
                        {urgency && (
                            <Urgent />
                        )}
                    </div>
                </CardContent>
            </CardActionArea>
        </Card >
    );
};

export default Item;
